import React, { useEffect, useState } from "react";
import CameraAltTwoToneIcon from "@mui/icons-material/CameraAltTwoTone";
import { Avatar, Button, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import StarBorderPurple500Icon from "@mui/icons-material/StarBorderPurple500";
import Footer from "../Footer/Footer";
import UserProfile from "../GlobalTable/UserProfile";
import dummyLogo from "../../Images/dummyLogo.png";
import * as themeColors from "../../Utils/Theme/Colors";
import { Controller, useForm } from "react-hook-form";
import VerifiedIcon from "@mui/icons-material/Verified";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { changePassword, updateClient } from "../../service/client.service";
import { notify } from "../../App";
import fetchAuthMe, { fetchAuthMeData } from "../../Redux/Slice/fetchAuthMe";

import { useDispatch } from "react-redux";

export const picUrl = (uri) => {
  return uri === null
    ? dummyLogo
    : process.env.REACT_APP_DOWLOAD_URL + "/" + uri;
};

const formatDate = (dateString) => {
  const options = { day: "numeric", month: "short", year: "numeric" };
  const formattedDate = new Date(dateString).toLocaleDateString(
    undefined,
    options
  );
  return formattedDate;
};

const PersonalDetailsNew = () => {
  const dispatch = useDispatch();
  const { theme, themeColorsObject } = useSelector((state) => state.theme);
  //console.log((themeColorsObject.backgroundColor);

  const { authInfo } = useSelector((state) => state?.fetchAuthMe);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [ isResetLoading, setIsResetLoading ] = useState(false)
  const [profileData, setProfileData] = useState({}); // State for profile data
  const [companyData, setCompanyData] = useState({}); // State for company data

  // Function to initialize profile data state with initial values
  useEffect(() => {
    const initialProfileData = {};
    profileDataArray.forEach(({ title, value }) => {
      initialProfileData[title] = value || "";
    });
    setProfileData(initialProfileData);
  }, []);

  // Function to initialize company data state with initial values
  // useEffect(() => {
  //   const initialCompanyData = {};
  //   companyDataArray?.forEach(({ title, value }) => {
  //     initialCompanyData[title] = value || '';
  //   });
  //   setCompanyData(initialCompanyData);
  // }, []);

  //console.log((authInfo);
  const [profileDetails, setProfileDetails] = useState({
    profilePic:
      process.env.REACT_APP_DOWLOAD_URL + "/" + authInfo?.profile_photo,
  });
  const metadata = authInfo?.metadata;
  //console.log((metadata);

  const profileDataArray = [
    {
      key: "first_name",
      title: "First Name",
      value: authInfo?.first_name,
    },
    {
      key: "last_name",
      title: "Last Name",
      value: authInfo?.last_name,
    },
    // {
    //   key:"date_of_birth",
    //   title: "Date Of Birth",
    //   value: authInfo?.date_of_birth,
    // },
    // {
    //   key:"gender",
    //   title: "Gender",
    //   value: authInfo?.gender,
    // },
    // {
    //   key:"register_date",
    //   title: "Register Date",
    //   value: formatDate(authInfo?.createdAt),
    // },
    {
      key: "phone_number",
      title: "Mobile No",
      value: authInfo?.phone_number,
    },
    {
      key: "email_address",
      title: "Email address",
      value: authInfo?.email_address,
    },
    // {
    //   key:"designation",
    //   title: "Designation",
    //   value: metadata?.designation,
    // },
    // {
    //   key:"department",
    //   title: "Department",
    //   value: metadata?.department,
    // },
    // {
    //   key:"city",
    //   title: "City",
    //   value: authInfo?.city,
    // },
    // {
    //   key:"state",
    //   title: "State",
    //   value: authInfo?.state,
    // },
    // {
    //   key:"country",
    //   title: "Country",
    //   value: authInfo?.country,
    // },
    // {
    //   key:"post_code",
    //   title: "Post code",
    //   value: authInfo?.post_code,
    // },
  ];

  // const companyDataArray = [
  //   {
  //     key:"business_email",
  //     title: "Business Name",
  //     value: authInfo?.business[0]?.business_name,
  //   },
  //   {
  //     key:"business_registration_no",
  //     title: "Business Reg No",
  //     value: authInfo?.business[0]?.business_registration_no,
  //   },
  //   {
  //     key:"business_url",
  //     title: "Business Url",
  //     value: authInfo?.business[0]?.business_url,
  //   },
  //   {
  //     key:"business_email",
  //     title: "Business Email",
  //     value: authInfo?.business[0]?.business_email,
  //   },
  //   {
  //     key:"business_type",
  //     title: "Business Type",
  //     value: authInfo?.business[0]?.business_type,
  //   },
  //   {
  //     key:"employees_range",
  //     title: "Employee Range",
  //     value: authInfo?.business[0]?.employees_range,
  //   },
  //   {
  //     key:"createdAt",
  //     title: "Register At",
  //     value: formatDate(authInfo?.business[0]?.createdAt),
  //   },
  // ];

  //console.log((companyDataArray["Employee Range"]);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    //console.log((file);
    const formData = new FormData();

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setProfileDetails({
          ...profileDetails,
          profilePic: event.target.result,
        });
      };
      reader.readAsDataURL(file);
    }

    formData.append("profile_photo", file);
    try {
      const res = await updateClient(formData);
      notify("Profile Updated Successfully");
      dispatch(fetchAuthMeData());
    } catch (error) {
      console.log(error?.response);
    }
  };

  // Event handler to update profile data state
  const handleProfileInputChange = (event, title) => {
    setProfileData({
      ...profileData,
      [title]: event.target.value,
    });
  };

  // Event handler to update company data state
  const handleCompanyInputChange = (event, title) => {
    setCompanyData({
      ...companyData,
      [title]: event.target.value,
    });
  };

  // Event handler for form submission
  const handleSubmit = async (event) => {
    setIsLoading(true)
    event.preventDefault();
    // Handle form submission here, e.g., send data to backend
    //console.log(("Profile Data:", profileData);
    //console.log(("Company Data:", companyData);

    // Create a new FormData object
    const formData = new FormData();

    // Append profile data to the FormData object
    profileDataArray.forEach(({ key, value, title }) => {
      formData.append(key, profileData[title]);
    });

    // Append company data to the FormData object
    // companyDataArray.forEach(({ key, value, title }) => {
    //   formData.append(key, companyData[title]);
    // });

    // Log the FormData object for verification
    //console.log(("FormData:", formData);

    //console.log(("hiited");
    try {
      const response = await updateClient(formData);
      setIsLoading(false)
      notify("Profile Updated Successfully");
      setIsEditing(false)
      dispatch(fetchAuthMeData());

    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  };


  const passwordValidationSchema = Yup.object().shape({
    password: Yup.string()
      .required("New password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      ),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("password"), null], "Password do not match"),
  });


  


  const onPasswordSubmit = async (data) => {
    setIsResetLoading(true)
    try {
      const res = await changePassword(data)
      notify(res?.data?.message)
      setIsResetLoading(false)
      reset()
      setIsPassword(false)


    } catch (error) {
      notify(error?.data?.error?.message, "error")
      setIsResetLoading(false)
      reset()
    }
  };



  const {
    handleSubmit: handlePasswordSubmit,
    formState: { errors: password_errors },
    reset,
    register,
  } = useForm({
    resolver: yupResolver(passwordValidationSchema),
  });


  

  const passwordField = [
    {
      title: "New Password",
      value: "",
      registerName:"password",
      passwordError: password_errors.password,
    },
    {
      title: "Confirm Password",
      value: "",
      registerName:"confirmPassword",
      passwordError: password_errors.confirmPassword,
    },
  ];

  return (
    <div
      style={{
        minHeight: "81vh",
      }}
      className="mt-5 mb-4 gap-3   flex-column  d-flex justify-content-start align-items-start"
    >
      <div
        style={{
          backgroundColor: themeColorsObject.megaCardBackgroundColor,
          color: themeColorsObject.textColor,
        }}
        className="w-100 cad-shadow border-white justify-content-between  rounded-2 gap-4 d-flex p-4"
      >
        <div className="gap-4 d-flex">
          <div
            style={{
              position: "relative",
            }}
          >
            <Avatar
              className="m-1"
              style={{
                width: "100px",
                height: "100px",
                border: "4px solid #E9E9E9",
              }}
              alt={authInfo?.first_name}
              src={profileDetails?.profilePic}
            />

            <input
              type="file"
              id="profile-image-input"
              accept="image/*"
              style={{
                display: "none",
              }}
              onChange={handleImageChange}
              className={`form-control cad-text-medium ${themeColorsObject.themed_input_class}`}
            />

            <label
              style={{
                position: "absolute",
                bottom: "0.8rem",
                right: "-0.5rem",
                height: "30px",
                width: "30px",
                backgroundColor: "#E9E9E9",
                padding: "4px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              htmlFor="profile-image-input"
            >
              <CameraAltTwoToneIcon
                sx={{
                  position: "absolute",
                  bottom: "0rem",
                  right: "0rem",
                  height: "30px",
                  width: "30px",
                  backgroundColor: "#E9E9E9",
                  padding: "4px",
                  borderRadius: "50%",
                  cursor: "pointer",
                  color: "#000",
                }}
              />
            </label>
          </div>

          <div className="pro-details d-flex justify-content-center flex-column">
            <span className="cad-fw-600 d-flex gap-2 cad-text-large">
              {authInfo?.first_name} {authInfo?.last_name}
              {/* <span
                style={{ color: "#4CCD8D" }}
                className="d-flex align-items-center  cad-text-large"
              >
                <VerifiedIcon />
              </span> */}
            </span>
            <span className="cad-fw-400 text-grey cad-text-medium">
              {authInfo?.phone_number}
            </span>
            <span className="cad-fw-400 text-grey cad-text-medium">
              {authInfo?.email_address}
            </span>
          </div>
        </div>
        <div className="d-flex align-items-end  gap-3"></div>
      </div>

      <div className="w-100 d-flex gap-3 justify-content-end align-items-center pe-3">
       {isPassword ? "" : <div className="col-1 d-flex align-items-center">
          <Button
            className="button-contained col-1"
            variant="contained"
            size="small"
            onClick={() => setIsEditing(!isEditing)}
          >
            {isEditing ? "Close" : "Edit"}
          </Button>
        </div>}

        <div className="col-1 d-flex align-items-center">
          <Button
            size="small"
            className="button-contained col-1"
            variant="contained"
            onClick={() => setIsPassword(!isPassword)}
          >
            {isPassword ? "Close" : "Password"}
          </Button>
        </div>
      </div>

      {isPassword ? 
        <div
          style={{
            backgroundColor: themeColorsObject.megaCardBackgroundColor,
            color: themeColorsObject.textColor,
          }}
          className="w-100  rounded-2 d-flex flex-wrap p-3"
        >
          <form
            style={{
              display: "block",
              width: "100%",
              backgroundColor: themeColorsObject.megaCardBackgroundColor,
              color: themeColorsObject.textColor,
            }}
            onSubmit={handlePasswordSubmit(onPasswordSubmit)}
          >
            <div className=" rounded-2 d-flex flex-wrap p-3">
              {passwordField.map(({ title, value, name, passwordError, registerName }, index) => (
                <div
                  className="col-3 mb-3 d-flex flex-column gap-1 p-2"
                  key={index}
                >
                  {/* <label
                      className="cad-text-small cad-fw-500 text-uppercase"
                      htmlFor={title}
                    >
                      {title}
                    </label> */}

                  <TextField
                    label={title}
                    type="password"
                    {...register(registerName)}
                    size="small"
                    error={!!passwordError}
                    helperText={passwordError?.message}
                  />
                </div>
              ))}
              <div className="w-100 d-flex justify-content-center">
                <div className="col-1 d-flex align-items-center">
                  <Button
                    className="button-contained col-1"
                    variant="contained"
                    size="small"
                    type="submit"
                  >{isResetLoading ? "Loading..." : 
                    "Update"}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div> : <div
        style={{
          backgroundColor: themeColorsObject.megaCardBackgroundColor,
          color: themeColorsObject.textColor,
        }}
        className="w-100 cad-shadow rounded-2 d-flex flex-wrap p-3"
      >
        {profileDataArray.map(({ title, value }, index) => (
          <div className="col-3 mb-3 d-flex flex-column gap-1 p-2" key={index}>
            <span className="cad-text-small cad-fw-500 text-uppercase">
              {title}
            </span>
            <span className="cad-text-medium cad-fw-600">{value}</span>
          </div>
        ))}

        {/* <span className="w-100 border-top mb-3" /> */}

        {/* {companyDataArray?.map(({ title, value }, index) => (
          <div className="col-3 mb-3 d-flex flex-column gap-1 p-2" key={index}>
            <span className="cad-text-small cad-fw-500 text-uppercase">
              {title}
            </span>
            <span className="cad-text-medium cad-fw-600">{value}</span>
          </div>
        ))} */}
      </div>}

      <div
        style={{
          overflow: "hidden",
          display: "block",
          height: isEditing ? "fit-content" : 0,
          opacity: isEditing ? 1 : 0,
          transition: "height 2s, opacity 1s",
          backgroundColor: themeColorsObject.megaCardBackgroundColor,
          color: themeColorsObject.textColor,
        }}
        className={`${isEditing ? "cad-shadow rounded-3" : ""} w-100`}
      >
        <form
          onSubmit={handleSubmit}
          style={{
            transform: isEditing ? "translateY(0)" : "translateY(-210px)",
            opacity: isEditing ? 1 : 0,
            zIndex: isEditing ? 1 : -2,
            transition: "transform 0.5s, opacity 1s",
            display: "block",
            backgroundColor: themeColorsObject.megaCardBackgroundColor,
            color: themeColorsObject.textColor,
          }}
        >
          <div className="w-100 rounded-2 d-flex flex-wrap p-3">
            {/* Rendering profile data input fields */}
            {profileDataArray.map(({ title, value }, index) => (
              <div
                className="col-3 mb-3 d-flex flex-column gap-1 p-2"
                key={index}
              >
                <span className="cad-text-small cad-fw-500 text-uppercase">
                  {title}
                </span>

                {title === "Email address" ? (
                  <span className="cad-text-medium cad-fw-600">{value}</span>
                ) : (
                  <input
                    className={`form-control cad-text-medium ${themeColorsObject.themed_input_class}`}
                    type={
                      title === "Date Of Birth" || title === "Register Date"
                        ? "date"
                        : "text"
                    }
                    value={profileData[title] || ""}
                    onChange={(event) => handleProfileInputChange(event, title)}
                  />
                )}
              </div>
            ))}

            {/* Rendering company data input fields */}
            {/* {companyDataArray?.map(({ title, value }, index) => (
              <div
                className="col-3 mb-3 d-flex flex-column gap-1 p-2"
                key={index}
              >
                <span className="cad-text-small cad-fw-500 text-uppercase">
                  {title}
                </span>
                <input
            className={`form-control cad-text-medium ${themeColorsObject.themed_input_class}`}
                  type={
                    title === "Date Of Birth" || title === "Register At"
                      ? "date"
                      : "text"
                  }
                  value={companyData[title] }
                  onChange={(event) => handleCompanyInputChange(event, title)}
                />
              </div>
            ))} */}
          </div>
          <div className="w-100 mb-4  mt-3 d-flex justify-content-center">
            <div className="col-1 d-flex align-items-center">
              <Button
                className="button-contained col-1"
                variant="contained"
                size="small"
                type="submit"
              >
                {isLoading ? "Loading..." : "Update"}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PersonalDetailsNew;
