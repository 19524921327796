// export const lightTheme = {
//     backgroundColor: "#F1F2F6",
//     cardBackgroundColor: "#fff",
//     megaCardBackgroundColor: "#fff",
//     titleText: "#0D1B2A",
//     textColor: "#000",
//     buttonColor: "#3498db",
//     app_bar_class:"app_bar_light",
//     side_btn_class:"slidebtn_light",
//     themed_input_class:"light_input"
//   };
  
export const darkTheme = {
  backgroundColor: "#292F4C",
  cardBackgroundColor: "#212B36",
  megaCardBackgroundColor: "#181B34",
  titleText: "#AACCDF",
  textColor: "#AACCDF",
  buttonColor: "#1F79FD",
  buttonHover:"#88888811",
  app_bar_class:"app_bar_dark",
  side_btn_class:"slidebtn_dark",
  placeholder: "#b3b3b3",
  themed_input_class:"dark_input",
  premiumCard:"#078eee2d"

};

export const lightTheme = {
  backgroundColor: "#F5F5F5",
  cardBackgroundColor: "#F5F5F5",
  megaCardBackgroundColor: "#FFFFFF",
  titleText: "#263238",
  textColor: "#130f40",
  buttonColor: "#078eee2d",
  buttonHover:"#88888811",
  placeholder: "#666565",
  app_bar_class:"app_bar_light",
  side_btn_class:"slidebtn_light",
  themed_input_class:"light_input",
  premiumCard:"#078eee2d"

};



export const darkBlackTheme = {
  backgroundColor: "#282828",
  cardBackgroundColor: "#282828",
  megaCardBackgroundColor: "#1A1A1A",
  titleText: "#B0BEC5",
  textColor: "#ECEFF1",
  placeholder: "#b3b3b3",
  buttonColor: "#1F79FD",
  buttonHover:"#88888811",
  app_bar_class:"app_bar_dark",
  side_btn_class:"slidebtn_dark",
  themed_input_class:"dark_input",
  premiumCard:"#078eee2d"
};


