import React, { useRef, useState } from "react";
import * as Yup from "yup";
import SignaturePad from "react-signature-canvas";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { addNewForm } from "../../Redux/Slice/getFormsSlice";
import { useSelector } from "react-redux";
import FormPreviewModal from "./FormPreviewModal";
import AddBoxIcon from "@mui/icons-material/AddBox";
import MainModal from "../../Components/MainModal";
import { createFilledForm } from "../../service/task.service";
import { notify } from "../../App";
import { useNavigate } from "react-router-dom";

const FormCanvas = ({
  formData,
  openPreviwModal,
  setOpenPreviewModal,
  formDataObject,
  setFormDataObject,
  setFormData,
  id1,
  id2,
  pdfRef,
  formAttachment,
  setFormAttachment,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [formDataObject, setFormDataObject] = useState([]);
  const [editableIndex, setEditableIndex] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const sigCanvas = useRef({});
  const [showButtons, setShowButtons] = useState(true);
  const { authInfo } = useSelector((state) => state?.fetchAuthMe);
  const profileData = authInfo;
  const [selectedImage, setSelectedImage] = useState(profileData?.business > 0 && profileData?.business[0]?.business_logo ? `${process.env.REACT_APP_DOWLOAD_URL}/${profileData?.business[0]?.business_logo}` : null);
  // console.log(profileData?.business[0]);

  // console.log(formDataObject);
  const clear = () => sigCanvas.current.clear();
  const save = () => {
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    setShowButtons(false);
  };

  const handleMouseEnter = () => {
    imageURL ? setShowButtons(false) : setShowButtons(true);
  };

  const handleMouseLeave = () => {
    setShowButtons(false);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormDataObject((prevFormData) => {
      // Find the index of the element with the matching name
      const index = prevFormData.findIndex((element) => element.name === name);

      if (index !== -1) {
        // If the element exists, update it
        return prevFormData.map((element, i) => {
          if (i === index) {
            if (type === "checkbox") {
              // If it's a checkbox, handle multiple values
              const currentValues = Array.isArray(element.value)
                ? element.value
                : [];
              const updatedCheckboxValues = checked
                ? [...currentValues, value]
                : currentValues.filter((val) => val !== value);
              return { ...element, value: updatedCheckboxValues, type: type };
            } else {
              // For other types of inputs
              return { ...element, value: value, type: type };
            }
          } else {
            return element;
          }
        });
      } else {
        // If the element doesn't exist, add it to the array
        return [...prevFormData, { name, value, type }];
      }
    });
  };

  // const handleChange = (e) => {
  //   const { name, value, type, checked } = e.target;
  //   const customAttribute = e.target.dataset.customAttribute;

  //   //console.log((customAttribute);

  //   //console.log((formDataObject);

  //   setFormDataObject((prevFormData) => {
  //     const index = prevFormData.findIndex((element) => element.name === name);
  //     //console.log((prevFormData);

  //     if (index !== -1) {
  //       return prevFormData.map((element, i) => {
  //         if (i === index) {
  //           if (type === "checkbox") {
  //             const currentValues = Array.isArray(element.value)
  //               ? element.value
  //               : [];
  //             const updatedCheckboxValues = checked
  //               ? [...currentValues, value]
  //               : currentValues.filter((val) => val !== value);
  //             return {
  //               ...element,
  //               value: updatedCheckboxValues,
  //               type,
  //               section: customAttribute,
  //             };
  //           } else {
  //             return {
  //               ...element,
  //               value: value,
  //               type: type,
  //               section: customAttribute,
  //             };
  //           }
  //         } else {
  //           return element;
  //         }
  //       });
  //     } else if (type === "details") {
  //       // Creating a new input object with an empty inputValue array
  //       const newInputObject = {
  //         name,
  //         value,
  //         type,
  //         section: customAttribute,
  //         inputValue: [value], // Assuming you want to initialize inputValue with the first value
  //       };

  //       return [
  //         ...prevFormData,
  //         newInputObject,
  //       ];
  //     } else {
  //       return [
  //         ...prevFormData,
  //         { name, value, type, section: customAttribute },
  //       ];
  //     }
  //   });

  //   //console.log((formDataObject);
  // };

  const handleChangeDetails = (e, rowIndex, columnIndex) => {
    const { name, value, type, checked } = e.target;
    const customAttribute = e.target.dataset.customAttribute;

    setFormDataObject((prevFormData) => {
      const updatedFormData = prevFormData.map((element) => {
        if (element.name === name) {
          const updatedInputValue = element.inputValue.map((row, index) => {
            if (index === rowIndex) {
              return row.map((input, inputIndex) => {
                if (inputIndex === columnIndex) {
                  return value;
                }
                return input;
              });
            }
            return row;
          });

          return {
            ...element,
            inputValue: updatedInputValue,
          };
        }
        return element;
      });

      return updatedFormData;
    });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   let form = e.target;
  //   //console.log((form);
  //   let formData = new FormData(form);
  //   let formObj = Array.fromEntries(formData.entries());
  //   //console.log((formObj);

  //   // Access the dynamic form data here
  //   //console.log(("Form Data:", formDataObject);
  //   // dispatch(addNewForm(formDataObject));
  //   // You can now send the data to your backend or perform any other necessary actions
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let form = e.target;

    //console.log((form);
    let formData = new FormData(form);

    // Convert FormData object to an array of key-value pairs
    let formArray = [];
    for (let [key, value] of formData.entries()) {
      // Get the input type
      const inputType = form.elements[key].type;
      //console.log((inputType);
      formArray.push({ name: key, value: value, type: inputType });
    }
    // //console.log((formArray);

    // Access the dynamic form data here
    const flattenedArray = [].concat(...formArray);
    //console.log((flattenedArray);
    //console.log(("Form Data:===========", flattenedArray);
    flattenedArray.splice(0, 0, formDataObject[0]);
    flattenedArray.push(formDataObject[1]);

    //console.log((flattenedArray);

    const formDataToSend = new FormData();
    const jsonData = JSON.stringify(flattenedArray);

    formDataToSend.append("formData", jsonData);
    //console.log((formDataToSend);

    try {
      const response = await createFilledForm(id1, id2, formDataToSend);
      //console.log((response);
      notify("Form sent");
      navigate("/");
      return response;
    } catch (error) {
      //console.log((error);
    }
  };

  function replaceSpacesWithUnderscores(str) {
    return str?.replace(/\s+/g, "_");
  }

  const handleCellChange = (e, rowIndex, cellIndex) => {
    const { name, value } = e.target;

    setFormDataObject((prevFormData) => {
      return prevFormData.map((element) => {
        // Check if the element has the same name as the input field's name
        if (element.name === name) {
          // Depending on the type of the element, update its value or other properties
          if (element.type === "table") {
            // For table elements, update the rows array
            const updatedRows = [...element.rows];
            updatedRows[rowIndex][cellIndex] = value;

            return {
              ...element,
              rows: updatedRows,
            };
          } else {
            // For other types of elements, update the value property
            return {
              ...element,
              value: value,
            };
          }
        }
        return element; // Return unchanged element if the name doesn't match
      });
    });
  };

  const handleFileChange = (event) => {

    console.log(event);




    const files = Array.from(event.target.files); // Convert FileList to array
    const formData = new FormData();

    let updateFiles = [...formAttachment];  // Copy existing attachments to avoid mutating state directly

    files.forEach((file, index) => {
      updateFiles.push(file);
    });
    
    setFormAttachment(updateFiles); // Update the state with the new array
    console.log(formAttachment);
  };

  // Function to render table rows
  const renderRows = (rows, theme) => {
    return rows.map((row, rowIndex) => (
      <tr key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td
            style={{
              backgroundColor: cellIndex === 0 ? "#E5EAF4" : "",
              border: `1px solid ${theme?.color}`,
              padding: cellIndex === 0 ? "8px 16px" : 0,
              minWidth: 100,
            }}
            className=" cad-text-small cad-fw-600"
            key={cellIndex}
          >
            {cellIndex === 0 ? (
              cell
            ) : (
              <input
                type="text"
                value={cell}
                name={`row-${rowIndex}-cell-${cellIndex}`} // Assign a unique name to each input field
                onChange={(e) => handleCellChange(e, rowIndex, cellIndex)}
                style={{
                  width: "100%", // Make input cover the full width of the cell
                  height: "100%", // Make input cover the full height of the cell
                  border: "none", // Remove border for the input
                  padding: 8, // Remove padding for the input
                  outline: "none", // Remove outline for the input
                  backgroundColor: "#fff", // Make input background transparent
                  boxSizing: "border-box", // Ensure input size includes padding and border
                  fontSize: "inherit", // Inherit font size from parent
                  fontFamily: "inherit", // Inherit font family from parent
                  fontWeight: "inherit", // Inherit font weight from parent
                }}
              />
            )}
          </td>
        ))}
      </tr>
    ));
  };

  const handleAddRow = () => {
    const detailsFieldIndex = formData?.findIndex(
      (field) => field?.type === "details"
    );

    console.log("=============================", detailsFieldIndex);

    if (detailsFieldIndex !== -1) {
      // let newDetailsField =
      //   formData[detailsFieldIndex] // Clone the existing details field
      // // Update any properties if needed

      let newDetailsField = JSON.parse(
        JSON.stringify(formData[detailsFieldIndex])
      );

      console.log(newDetailsField);

      newDetailsField.detailsTitle = null;
      newDetailsField.subTitle = null;

      // Add the cloned details field to the formData array
      const updatedformData = [
        ...formData.slice(0, detailsFieldIndex + 1),
        newDetailsField,
        ...formData.slice(detailsFieldIndex + 1),
      ];
      setFormData(updatedformData);
    }
  };

  //console.log((formData);

  const formElements = (
    <div className="p-4  d-flex w-100 gap-3 justify-content-center flex-column align-items-start position-relative overflow-hidden">

      <span className="d-flex" style={{
        position: "absolute",
        top: "40%",
        left: "-5%",
        opacity: 0.1,
        transform: "rotate(-38deg)"
      }}>
        <span
          className="cad-fw-800  d-flex align-items-end"
          style={{
            color: "#4CCD8D",
            fontSize: "150px",
            // opacity: open ? 0 : 1,
            transform: "translateX(0px)",
            transition: "0.5s",
          }}
        >
          Cloudify
        </span>
        <span
          className=" d-flex text-primary align-items-end cad-fw-500"
          style={{
            fontSize: "152px",
            // opacity: open ? 0 : 1,
            transform: "translateX(0px)",
            transition: "0.8s",
          }}
        >
          Docs
        </span>
      </span>

      <div className="d-flex w-100 flex-wrap ">
        {formData?.map((field, index) => {
          switch (field.type) {
            case "header":
              return (
                <div
                  // onDragOver={(e)=>handleElementDragOver(e, index)}
                  className="w-100 mb-3"
                >
                  <div
                    key={field.id}
                    style={{
                      borderBottom: `1px solid ${formData[0].color}`,
                    }}
                    className={` position-relative w-100 d-flex align-items-end `}
                  >
                    {selectedImage && <img style={{
                      // position:"absolute",
                      top: 0,
                      left: 0,
                      width: "20%",
                      margin: 14,
                      objectFit: "cover"
                    }} src={selectedImage} />}
                    <span style={{ width: "80%" }} className={`${field.alignment}   p-2 pt-2 pb-0    w-100 d-flex justify-content-center  flex-column`}>
                      <h3 className={`${field.className} cad-fw-700`}>
                        {field.label}
                      </h3>
                      <p className={field.subTitleClassName}>
                        {field.subTitle}
                      </p>
                    </span>
                  </div>
                </div>
              );

            case "text":
              return (
                <div className="col-6">
                  <div
                    className={` position-relative p-2 gap-2 d-flex flex-column`}
                  >
                    <label className="cad-text-medium cad-fw-500">
                      {field.label}
                    </label>
                    <input
                      value={formData[field.name]}
                      onChange={handleChange}
                      name={replaceSpacesWithUnderscores(field.label)}
                      data-custom-attribute={field.type}
                      style={{
                        borderColor: formData[0]?.color,
                      }}
                      required={field.required}
                      type={field.type}
                      className={field.className}
                      placeholder={field.placeHolder}
                    />
                    <span className="cad-text-small ">{field?.subLabel}</span>
                  </div>
                </div>
              );

            case "tel":
              return (
                <div
                  // onDragOver={(e)=>handleElementDragOver(e, index)}
                  className="col-6"
                >
                  <div
                    key={field.id}
                    // dragable
                    // onDragStart={(e) => handleElementDragStart(e, index)}
                    // onDragOver={(e) => handleElementDragOver(e, index)}
                    // onDoubleClick={() => setEditableIndex(index)}
                    className="position-relative  p-2 gap-2 d-flex flex-column"
                  >
                    <label className="cad-text-medium cad-fw-500">
                      {field.label}
                    </label>
                    <input
                      value={formData[field.name]}
                      onChange={handleChange}
                      name={replaceSpacesWithUnderscores(field.label)}
                      style={{
                        borderColor: formData[0]?.color,
                      }}
                      required={field.required}
                      type="number"
                      className={field.className}
                      placeholder={field.placeHolder}
                      data-custom-attribute={field.type}
                    />
                    <span className="cad-text-small ">{field?.subLabel}</span>
                  </div>
                </div>
              );

            case "date":
              return (
                <div
                  // onDragOver={(e)=>handleElementDragOver(e, index)}
                  className="col-6"
                >
                  <div className="position-relative p-2 gap-2 d-flex flex-column">
                    <label className="cad-text-medium cad-fw-500">
                      {field.label}
                    </label>
                    <input
                      value={formData[field.name]}
                      onChange={handleChange}
                      name={replaceSpacesWithUnderscores(field.label)}
                      style={{
                        borderColor: formData[0]?.color,
                      }}
                      required={field.required}
                      type={field.type}
                      className={field.className}
                      placeholder={field.placeHolder}
                      data-custom-attribute={field.type}
                    />
                    <span className="cad-text-small ">{field?.subLabel}</span>
                  </div>
                </div>
              );

            case "email":
              return (
                <div
                  // onDragOver={(e)=>handleElementDragOver(e, index)}
                  className="col-6"
                >
                  <div className="position-relative p-2 gap-2 d-flex flex-column">
                    <label className="cad-text-medium cad-fw-500">
                      {field.label}
                    </label>
                    <input
                      value={formData[field.name]}
                      onChange={handleChange}
                      name={replaceSpacesWithUnderscores(field.label)}
                      style={{
                        borderColor: formData[0]?.color,
                      }}
                      required={field.required}
                      type={field.type}
                      className={field.className}
                      placeholder={field.placeHolder}
                      data-custom-attribute={field.type}
                    />
                    <span className="cad-text-small ">{field?.subLabel}</span>
                  </div>
                </div>
              );

            case "textarea":
              return (
                <div
                  // onDragOver={(e)=>handleElementDragOver(e, index)}
                  className="col-12"
                >
                  <div className="position-relative p-2 gap-2 d-flex flex-column">
                    <label className="cad-text-medium cad-fw-500">
                      {field.label}
                    </label>
                    <textarea
                      value={formData[field.name]}
                      onChange={handleChange}
                      name={replaceSpacesWithUnderscores(field.label)}
                      style={{
                        borderColor: formData[0]?.color,
                      }}
                      className="form-control"
                      placeholder={field.placeHolder}
                      data-custom-attribute={field.type}
                      id="floatingTextarea"
                    ></textarea>
                    <span className="cad-text-small ">{field?.subLabel}</span>
                  </div>
                </div>
              );

            case "submit":
              return (
                <div
                  // onDragOver={(e)=>handleElementDragOver(e, index)}
                  className="col-12"
                >
                  <div
                    key={field.id}
                    // dragable
                    // onDragStart={(e) => handleElementDragStart(e, index)}
                    // onDragOver={(e) => handleElementDragOver(e, index)}
                    onDoubleClick={() => setEditableIndex(index)}
                    className={`${field.alignment} position-relative p-4 pb-3 gap-2 d-flex `}
                  >
                    <button
                      type="submit"
                      style={{
                        backgroundColor: formData[0]?.color,
                        color: formData[0]?.formColor,
                      }}
                      // type={field.type}
                      className="btn"
                    >
                      {field.label}
                    </button>
                  </div>
                </div>
              );

            case "dropdown":
              return (
                <div
                  // onDragOver={(e)=>handleElementDragOver(e, index)}
                  className="col-6"
                >
                  <div className=" position-relative p-2 gap-2 d-flex flex-column">
                    <label className="cad-text-medium cad-fw-500">
                      {field.label}
                    </label>
                    <select
                      style={{
                        borderColor: formData[0]?.color,
                      }}
                      type="text"
                      value={formData[field.name]}
                      onChange={handleChange}
                      name={replaceSpacesWithUnderscores(field.label)}
                      className={field.className}
                      aria-label="Default select example"
                      data-custom-attribute={field.type}
                    >
                      <option selected>Open this select menu</option>
                      {field?.options?.map(({ option }, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    <span className="cad-text-small ">{field?.subLabel}</span>
                  </div>
                </div>
              );

            case "paragraph":
              return (
                <div
                  // onDragOver={(e)=>handleElementDragOver(e, index)}
                  className="col-12"
                >
                  <div
                    key={field.id}
                    // dragable
                    // onDragStart={(e) => handleElementDragStart(e, index)}
                    // onDragOver={(e) => handleElementDragOver(e, index)}
                    onDoubleClick={() => setEditableIndex(index)}
                    className={`${field.alignment} position-relative p-2 gap-2 d-flex flex-column`}
                  >
                    <p className={`${field.className}  cad-fw-500`}>
                      {field.label}
                    </p>
                  </div>
                </div>
              );

            case "file":
              return (
                <div
                  // onDragOver={(e)=>handleElementDragOver(e, index)}
                  className="col-12"
                >
                  <div
                    key={field.id}
                    // dragable
                    // onDragStart={(e) => handleElementDragStart(e, index)}
                    // onDragOver={(e) => handleElementDragOver(e, index)}
                    onDoubleClick={() => setEditableIndex(index)}
                    className="position-relative p-2 gap-2 d-flex flex-column"
                  >
                    <label htmlFor="formFile" className="form-label">
                      {field.label}
                    </label>
                    <input
                      value={formData[field.name]}
                      onChange={handleFileChange}
                      name={replaceSpacesWithUnderscores(field.label)}
                      style={{
                        borderColor: formData[0]?.color,
                      }}
                      className={field.className}
                      type={field.type}
                      id="formFile"
                      multiple={field?.multiple}
                    />
                    <span className="cad-text-small ">{field?.subLabel}</span>
                  </div>
                </div>
              );

            case "hr":
              return (
                <div
                  // onDragOver={(e)=>handleElementDragOver(e, index)}
                  className="col-12"
                >
                  <div
                    key={field.id}
                    // dragable
                    // onDragStart={(e) => handleElementDragStart(e, index)}
                    // onDragOver={(e) => handleElementDragOver(e, index)}
                    onDoubleClick={() => setEditableIndex(index)}
                    className="position-relative p-2 gap-2 d-flex flex-column"
                  >
                    {/* <hr color={formData[0].color} /> */}
                    <span
                      className="mt-2 mb-2"
                      style={{
                        borderTop: `0.5px solid ${formData[0].color}`,
                      }}
                    />
                  </div>
                </div>
              );

            case "checkbox":
              return (
                <div
                  // onDragOver={(e)=>handleElementDragOver(e, index)}
                  className="col-6"
                >
                  <div className=" position-relative p-2">
                    <label className="cad-text-medium cad-fw-500 mb-2">
                      {field.label}
                    </label>
                    {field?.options?.map(({ option }, index) => (
                      <div
                        key={index}
                        className="form-check d-flex justify-content-start w-100 gap-2"
                      >
                        <input
                          value={option}
                          onChange={handleChange}
                          name={replaceSpacesWithUnderscores(field.label)}
                          id={`checkbox-${field.name}-${index}`}
                          checked={formData[field.name]?.includes(option)}
                          className="form-check-input cad-text-medium"
                          type={field.type}
                          data-custom-attribute={field.type}
                        />
                        <label
                          className="form-check-label col-12 cad-text-small"
                          htmlFor={`checkbox-${field.name}-${index}`}
                        >
                          {option}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              );

            case "radio":
              return (
                <div
                  // onDragOver={(e)=>handleElementDragOver(e, index)}
                  style={{
                    minWidth: "50%",
                  }}
                >
                  <div key={field.id} className=" position-relative p-2">
                    <label className="cad-text-medium cad-fw-500 mb-2">
                      {field.label}
                    </label>
                    {field?.options?.map(({ option }, index) => (
                      <div
                        key={index}
                        className="form-check d-flex justify-content-start w-100 gap-2"
                      >
                        <input
                          value={option}
                          onChange={handleChange}
                          name={replaceSpacesWithUnderscores(field.label)}
                          id={`checkbox-${field.name}-${index}`}
                          data-custom-attribute={field.type}
                          checked={formData[field.name]?.includes(option)}
                          className="form-check-input cad-text-medium"
                          type={field.type}
                        />
                        <label
                          className="form-check-label col-12 cad-text-small"
                          id={`checkbox-${field.name}-${index}`}
                        >
                          {option}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              );

            case "address":
              return (
                <div className="col-12">
                  <div
                    key={field.id}
                    className="position-relative p-2 gap-2 d-flex flex-column"
                  >
                    <label className="cad-text-medium cad-fw-500">
                      {field.label}
                    </label>
                    <div className="d-flex flex-wrap w-100">
                      <div className="w-100 d-flex justify-content-start flex-column mb-2">
                        <textarea
                          value={formData[field.name]}
                          onChange={handleChange}
                          name={replaceSpacesWithUnderscores(field.street)}
                          style={{
                            borderColor: formData[0]?.color,
                          }}
                          type="text"
                          data-custom-attribute={field.type}
                          className="form-control w-100 cad-text-medium rounded-0  m-1"
                          placeholder={field?.street}
                        />
                        <span className="cad-text-small ps-1">
                          {field?.street}
                        </span>
                      </div>
                      <div className="col-6 pe-3 flex-column d-flex justify-content-start mb-2">
                        <input
                          value={formData[field.name]}
                          onChange={handleChange}
                          name={replaceSpacesWithUnderscores(field.city)}
                          type="text"
                          data-custom-attribute={field.type}
                          style={{
                            borderColor: formData[0]?.color,
                          }}
                          className="form-control cad-text-medium rounded-0 m-1 "
                          placeholder={field?.city}
                        />
                        <span className="cad-text-small ps-1">
                          {field?.city}
                        </span>
                      </div>

                      <div className="col-6  flex-column d-flex justify-content-end mb-2">
                        <input
                          value={formData[field.name]}
                          onChange={handleChange}
                          name={replaceSpacesWithUnderscores(field.state)}
                          type="text"
                          data-custom-attribute={field.type}
                          style={{
                            borderColor: formData[0]?.color,
                          }}
                          className="form-control rounded-0 cad-text-medium m-1 "
                          placeholder={field?.state}
                        />
                        <span className="cad-text-small ps-1">
                          {field?.state}
                        </span>
                      </div>

                      <div className="col-6 pe-3 flex-column d-flex justify-content-end mb-2">
                        <input
                          value={formData[field.name]}
                          onChange={handleChange}
                          name={replaceSpacesWithUnderscores(field.country)}
                          type="text"
                          data-custom-attribute={field.type}
                          style={{
                            borderColor: formData[0]?.color,
                          }}
                          className="form-control rounded-0 cad-text-medium m-1 "
                          placeholder={field?.country}
                        />
                        <span className="cad-text-small ps-1">
                          {field?.country}
                        </span>
                      </div>

                      <div className="col-6 flex-column  d-flex justify-content-end mb-2">
                        <input
                          value={formData[field.name]}
                          onChange={handleChange}
                          name={replaceSpacesWithUnderscores(field.zipCode)}
                          type="text"
                          data-custom-attribute={field.type}
                          style={{
                            borderColor: formData[0]?.color,
                          }}
                          className="form-control rounded-0 cad-text-medium m-1 "
                          placeholder={field?.zipCode}
                        />
                        <span className="cad-text-small ps-1">
                          {field?.zipCode}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );

            case "footer":
              return (
                <div
                  // onDragOver={(e)=>handleElementDragOver(e, index)}
                  className="w-100 mt-3"
                >
                  <div
                    key={field.id}
                    // dragable
                    // onDragStart={(e) => handleElementDragStart(e, index)}
                    // onDragOver={(e) => handleElementDragOver(e, index)}
                    // element-edit
                    onDoubleClick={() => setEditableIndex(index)}
                    style={{
                      borderTop: `1px solid ${formData[0].color}`,
                    }}
                    className={`${field.alignment} position-relative ps-4 pt-2 pb-3 pe-4 pb-0 w-100 d-flex justify-content-between `}
                  >
                    <span className={`${field.className} cad-fw-700`}>
                      {field.label}
                    </span>
                    <span className={field.subTitleClassName}>
                      {field.subTitle}
                    </span>
                  </div>
                </div>
              );

            case "table":
              return (
                <div
                  // element-edit
                  key={field.id}
                  className={` position-relative p-2  gap-2 w-100 d-flex flex-column`}
                >
                  <table key={field.id} className="w-100 position-relation">
                    <thead>
                      <tr>
                        {field.columns.map((column, index) => (
                          <th
                            style={{
                              backgroundColor: index === 0 ? "" : "#E5EAF4",

                              border:
                                index === 0
                                  ? ""
                                  : `1px solid ${formData[0]?.color}`,
                            }}
                            className={`p-3 pt-2 pb-2  cad-fw-600 cad-text-small position-relation`}
                            key={index}
                          >
                            {column.heading}
                          </th>
                        ))}
                        {/* <span
                          style={{
                            display: "inline-block", // Ensure the element is block-level to apply transformations
                            transform: "rotate(90deg)",
                            width: 110,
                            top: "40%",
                            right: -60,
                          }}
                          className="d-flex justify-content-center cad-fw-600 action-hover  rounded-2  align-items-center pointer cad-text-small position-absolute"
                        >
                          Add column +
                        </span> */}
                      </tr>
                    </thead>
                    <tbody>
                      {field.rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {row.map((cell, cellIndex) => (
                            <td
                              style={{
                                backgroundColor:
                                  cellIndex === 0 ? "#E5EAF4" : "",
                                border: `1px solid ${formData[0]?.color}`,
                                padding: cellIndex === 0 ? "8px 16px" : 0,
                                minWidth: 100,
                              }}
                              className=" cad-text-small cad-fw-600"
                              key={cellIndex}
                            >
                              {cellIndex === 0 ? (
                                cell
                              ) : (
                                <input
                                  type="text"
                                  // value={cell}
                                  name={`row-${rowIndex}-cell-${cellIndex}`}
                                  // onChange={handleChange}
                                  style={{
                                    width: "100%", // Make input cover the full width of the cell
                                    height: "100%", // Make input cover the full height of the cell
                                    border: "none", // Remove border for the input
                                    padding: 8, // Remove padding for the input
                                    outline: "none", // Remove outline for the input
                                    backgroundColor: "transperant", // Make input background transparent
                                    boxSizing: "border-box", // Ensure input size includes padding and border
                                    fontSize: "inherit", // Inherit font size from parent
                                    fontFamily: "inherit", // Inherit font family from parent
                                    fontWeight: "inherit", // Inherit font weight from parent
                                  }}
                                />
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {/* <span
                    style={{}}
                    className="pointer cad-fw-600 action-hover ps-2 rounded-2 pe-2 m-auto cad-text-small"
                  >
                    Add row +
                  </span> */}
                </div>
              );

            case "details":
              return (
                <div className="position-relative d-flex gap-0 flex-wrap w-100">
                  <label className="col-12 postion-relative mb-1 mt-2 cad-fw-600">
                    {field?.detailsTitle}
                  </label>

                  {field?.detailsTitle && (
                    <span
                      style={{
                        position: "absolute",
                        right: 6,
                        top: -6,
                      }}
                      onClick={handleAddRow}
                      className="text-white float-end mt-2 action-hover pointer rounded-2 overflow-hidden"
                    >
                      <AddBoxIcon
                        style={{
                          fontSize: "2rem",
                          backgroundColor: formData[0]?.color,
                        }}
                      />
                    </span>
                  )}

                  {field?.inputsArray.map((input, index) => (
                    <div
                      key={index}
                      className="col-3 pe-2 ps-0 flex-column d-flex justify-content-start mb-2"
                    >
                      <input
                        // value={formData[field.name]}
                        // onChange={handleChange}
                        name={replaceSpacesWithUnderscores(input?.fieldInput)}
                        data-custom-attribute={field?.type}
                        type={field?.type}
                        style={{
                          borderColor: formData[0]?.color,
                        }}
                        className="form-control cad-text-small m-1 rounded-0 "
                        placeholder={input?.fieldInput}
                      />
                      <span className="cad-text-small ps-1">
                        {input?.fieldInput}
                      </span>
                    </div>
                  ))}
                </div>
              );

            case "details-heading":
              return (
                <div className="col-12">
                  <div
                    key={field.id}
                    // // dragable
                    // onDragStart={(e) => handleElementDragStart(e, index)}
                    // onDragOver={(e) => handleElementDragOver(e, index)}
                    // onDoubleClick={() => setEditableIndex(index)}
                    className="position-relative p-2 gap-2 d-flex flex-column"
                  >
                    <label className="cad-text-large cad-fw-700">
                      {field.label}
                    </label>
                    <label className="cad-text-medium cad-fw-500">
                      {field.subTitle}
                    </label>
                  </div>
                </div>
              );

            case "signeture":
              return (
                <div className="col-12 mt-5 pt-5">
                  <div
                    onDoubleClick={() => setEditableIndex(index)}
                    className="col-12 d-flex flex-column justify-content-end align-items-end pe-2"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    {showButtons && showButtons ? (
                      <>
                        <SignaturePad
                          ref={sigCanvas}
                          penColor={formData[0]?.color}
                          canvasProps={{
                            className: " bg-white",
                            style: {
                              borderBlockColor: formData[0]?.color,
                              width: "35%",
                              height: "120px",
                              border: "1px solid",
                              borderColor: formData[0]?.color,
                            },
                          }}
                        />
                        {/* Button to trigger save canvas image */}
                        <div className="w-25 position-relative d-flex justify-content-end cad-fw-600 cad-text-large pt-2">
                          <span>Signature</span>
                          {showButtons && (
                            <div
                              style={{
                                left: -80,
                                top: 0,
                              }}
                              className="position-absolute "
                            >
                              <span className="pointer me-3 text-success">
                                <CheckIcon onClick={save} />
                              </span>
                              <span className="pointer text-danger">
                                <CloseIcon onClick={clear} />
                              </span>
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <img
                          src={imageURL}
                          alt=""
                          style={{
                            // borderBlockColor: imageURL? "" : formData[0]?.color,
                            width: "20%",
                            height: "80px",
                            // border:  imageURL? "" :"1px solid",
                            padding: "6px",
                            // borderColor:  imageURL? "" : formData[0]?.color,
                            objectFit: "contain",
                          }}
                        />

                        <div className="w-25 position-relative d-flex justify-content-end cad-fw-600 cad-text-large pt-2">
                          <span>Signature</span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              );

            default:
              return null;
          }
        })}
      </div>
    </div>
  );

  return (
    <>
      <form
        ref={pdfRef}
        onSubmit={handleSubmit}
        style={{
          backgroundColor: formData[0]?.formColor,
          color: formData[0]?.color,
          height: "fit-content",
        }}
        className=" col-8 mt-3  cad-shadow rounded-3"
      >
        {formElements}
      </form>

      <FormPreviewModal
        open={openPreviwModal}
        formData={formData}
        setOpen={setOpenPreviewModal}
      />

      {/* <MainModal
        title={"Form Preview"}
        open={openPreviwModal}
        width={"60%"}
        handleClose={() => setOpenPreviewModal(!openPreviwModal)}
      >
        <div 
      style={{
        backgroundColor: formData[0]?.formColor,
        color: formData[0]?.color,
        height: "fit-content",
      }} className="w-100 cad-shadow rounded-3 mb-2">
      {formElements}
        </div>
      </MainModal> */}
    </>
  );
};

export default FormCanvas;
