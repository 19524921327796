import { Avatar, Badge, Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import UserProfile from "../GlobalTable/UserProfile";
import { createOrGetOneOnOneChat, getChatList } from "../../service/chat.service";
import { getAgent } from "../../service/global.service";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const NewChatModal = ({
  openNewChat,
  handleOpenNewChat,
  handleCloseNewChat,
  contacts,
  setChatContacts
}) => {
  const [mouseEntered, setMouseEntered] = useState(false);


  const handleChatClick = async (id) => {
    try {
      const response = await createOrGetOneOnOneChat(id);
      //console.log((response);
    } catch (error) {
      //console.log((error);
    }

    handleCloseNewChat()
  };



  return (
    <Modal
      open={openNewChat}
      onClose={handleCloseNewChat}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="cad-modal-blur"
    >
      <div
        className="rounded-2"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 480,
          height: "80%",
          backgroundColor: "#fff",
          boxShadow: 24,
          p: 4,
        }}
      >
        <div className="d-flex justify-content-center">
          <h4 className="p-3 cad-fw-700">New Chat</h4>
        </div>
        <div className="w-100 mb-3 d-flex justify-content-center">
          <input
            type="text"
            className="form-control searchbox"
            placeholder="Search..."
          />
        </div>
        <div className="w-100 d-flex flex-column">
          {contacts?.map(
            ({ id, first_name, last_name, profile_photo }, index) => (
              <span
                onClick={() => handleChatClick(id)}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "transparent";
                  e.currentTarget.style.transition = "0.5s";
                  e.currentTarget.style.boxShadow =
                    "rgba(0, 0, 0, 0.24) 0px 3px 8px";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "transparent"; // Revert to default background color on hover out
                  e.currentTarget.style.scale = 1;
                  e.currentTarget.style.transition = "0.5s";
                  e.currentTarget.style.boxShadow = "none";
                }}
                style={{
                  cursor: "pointer",
                }}
                key={id}
                className="ms-3 me-3 border-bottom p-2  d-flex gap-2 align-items-center "
              >
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                >
                  <Avatar alt={first_name} src={profile_photo} />
                </StyledBadge>
                <span className="cad-text-medium ms-1 cad-fw-600">
                  {first_name} {last_name}
                </span>
              </span>
            )
          )}
        </div>
      </div>
    </Modal>
  );
};

export default NewChatModal;
