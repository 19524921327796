import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getKanban } from "../../service/task.service";

export const fetchKanbanData = createAsyncThunk("fetchKanbanData", async () => {
  try {
    const res = await getKanban();
    // //console.log((res);
    return res.data;
  } catch (err) {
    throw new Error(`Error fetching kanban data: ${err.message}`);
  }
});

const kanbanSlice = createSlice({
  name: "fetchKanban",
  initialState: {
    isLoading: true,
    isError: false,
    error: null,
    kanbanData: [], // Property to store fetched kanban data
    mappingKanbanData: [],
  },
  reducers: {
    // You can add additional reducers specific to kanban slice if needed
    updateMappingKanbanData(state, action) {
      state.mappingKanbanData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchKanbanData.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchKanbanData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.kanbanData = action.payload.data; // Update the kanbanItems property with the fetched data

        const order = ["Open", "In Progress", "On Hold", "Completed"];
        const reorderedTasks = [];

        order.forEach((status) => {
          const tasksInStatus = action?.payload?.data?.filter(
            (task) => task?.title === status
          );
          tasksInStatus && reorderedTasks?.push(...tasksInStatus);
        });
        state.mappingKanbanData = reorderedTasks;
      })
      .addCase(fetchKanbanData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message; // Store the error message
      });
  },
});
export const { updateMappingKanbanData } = kanbanSlice.actions;

export default kanbanSlice.reducer;
