import React, { useContext, useState } from "react";
import GlobalStateProvider from "./Contexts/GlobalStateProvider";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Main from "./Routes/Routes";
import Login from "./Pages/Login/Login";
import DefaultLayout, { ProtectedRoute } from "./layout/defaultLayout";
import { Route, Routes, useNavigate } from "react-router-dom";
import GlobalStateContext from "./Contexts/GlobalStateContext";
import { Button } from "@mui/material";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import ConfirmPassword from "./Pages/ConfirmPassword/ConfirmPassword";
import Task from "./Pages/Task/Task";
import { useSelector } from "react-redux";
import * as themeColors from "./Utils/Theme/Colors";
import { ToastContainer, Zoom, toast } from "react-toastify";

export const notify = (message, type) => {
  if (type === "error") {
    toast.error(message, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Zoom,
    });
  } else if (type === "warn") {
    toast.warn(message, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Zoom,
    });
  } else {
    toast.success(message, {
      position: "bottom-left",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Zoom,
    });
  }
};

function App() {
  const { theme, themeColorsObject } = useSelector((state) => state.theme);

  const craftedMsg = "Crafted with ❤️ by WDI Team for a better web.";
  const websiteMsg = "Website: www.wdipl.com";

  const craftedStyles =
    "font-size: 16px; font-family: monospace; background: #000; color: #E5195E; padding: 12px 19px; border: 1.8px dashed; border-right: 0px #000 solid";
  const websiteStyles =
    "font-size: 16px; font-family: monospace; background: #E5195E; color: #000; padding: 12px 19px; border: 1.8px dashed #000; border-left: 0px #000 solid";

  // console.log(
  //   `%c ${craftedMsg} %c ${websiteMsg}`,
  //   craftedStyles,
  //   websiteStyles
  // );

  return (
    <div
      style={{
        backgroundColor: themeColorsObject.backgroundColor,
      }}
    >
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/*" element={<DefaultLayout />} />
        <Route path="/forget-password-change" element={<ForgotPassword />} />

        {/* <Route path="/*" element={<DefaultLayout />} /> */}
      </Routes>

      <ToastContainer className="cad-text-medium" />
    </div>
  );
}

export default App;
