import { Modal } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

const MainModal = ({ children, open, handleClose, title, width, height, blur }) => {
  const { theme, themeColorsObject } = useSelector((state) => state.theme);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={blur ? "cad-modal-blur" : ""}
    >
      <div
        style={{
          height: height? height : "90%",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          // width: 400,
          width:width,
          backgroundColor: themeColorsObject.megaCardBackgroundColor,
          color: themeColorsObject.textColor,
          boxShadow: 24,
          p: 4,
        }}
        className="d-flex overflow-auto rounded-3 position-relative  cad-shadow p-4 pb-0 flex-column gap-2"
      >
        <div className="w-100 mb-3 d-flex align-items-center justify-content-between">
          <span className="cad-text-large text-primary cad-fw-600">{title}</span>
          <span onClick={handleClose} className="action-hover rounded-2 pointer text-primary">
            <CloseIcon />
          </span>
        </div>
        {children}
      </div>
      
    </Modal>
  );
};

export default MainModal;
