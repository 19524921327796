import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import NewDrawerPersistace from "../Components/NewDrawerPersistace";

const DefaultLayout = () => {
  const navigate = useNavigate()
  const user = JSON.parse(localStorage.getItem("user"))
  useEffect(() => {
    // console.log(user);
    if (!user?.isAuthenticate) {
      navigate('/login');
      return;
    }
  }, [navigate, user])

  return (
    <NewDrawerPersistace />
  );
};



// export const ProtectedRoute = ({ component: component, ...rest }) => {
//   const navigate = useNavigate()
//   const user = JSON.parse(localStorage.getItem("user"))
//   // if (!user) navigate('/login')
//   return !user ? <Outlet /> : navigate('/login')
// }

// export const PrivatRoute = ({ children }) => {
//   // const navigate = useNavigate()
//   const user = JSON.parse(localStorage.getItem("user"))
//   // if (!user) navigate('/login')
//   return !user ? children : <Navigate to={`/login`} />
// }


export default DefaultLayout;
