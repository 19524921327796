import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSharedDocs } from "../../service/task.service";

export const fetchSharedDocs = createAsyncThunk(
  "getSharedDocs",
  async (id) => {
    try {
      const res = await getSharedDocs(id);
      return res.data;
    } catch (err) {
      throw new Error(`Error fetching shared docs: ${err.message}`);
    }
  }
);

const getSharedDocsSlice = createSlice({
  name: "shared-docs",
  initialState: {
    isLoading: false,
    isError: false,
    error: null,
    sharedDocuments: [],
  },
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSharedDocs.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchSharedDocs.fulfilled, (state, action) => {
        state.sharedDocuments = action.payload?.data || [];
        state.isLoading = false;
      })
      .addCase(fetchSharedDocs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message;
      });
  },
});

export default getSharedDocsSlice.reducer;
