import api from "./api.service";
let formDataConfig = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

let headers = {
  "Content-Type": "multipart/form-data",
};

export const createTask = async (taskData) => {
  try {
    const response = await api.post(`/task/agent/`, taskData);
    return response;
  } catch (error) {
    // Handle error or return the error response
    return error.response;
  }
};

export const deleteTask = async (id) => {
  try {
    const response = await api.delete(`/task/agent/delete/${id}`);
    return response.data; // Assuming the deleted data is returned from the server
  } catch (error) {
    return error.response;
  }
};

export const getTaskById = async (id) => {
  try {
    return await api.get(`/task/client/${id}`);
  } catch (error) {
    return error.response;
  }
};

export const getKanban = async () => {
  try {
    return await api.get(`/task/client/kanban`);
  } catch (error) {
    return error.response;
  }
};

export const updateCompleteTask = async (id) => {
  try {
    return await api.post(`/task/client/${id}`);
  } catch (error) {
    return error.response;
  }
};

export const sharedDocs = async (docData, id) => {
  try {
    const response = await api.post(
      `/task/client/type/${id}/sharedDoc/`,
      docData,
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    // Handle error or return the error response
    return error.response;
  }
};

export const getSharedDocs = async (id) => {
  try {
    return await api.get(`/task/client/type/${id}/sharedDoc/`);
  } catch (error) {
    return error.response;
  }
};

export const deleteShareDocs = async (id) => {
  try {
    const response = await api.delete(
      `/task/agent/type/sharedDoc/delete/${id}`
    );
    return response.data; // Assuming the deleted data is returned from the server
  } catch (error) {
    return error.response;
  }
};

export const getRequestDocs = async (id) => {
  try {
    return await api.get(`/task/agent/type/${id}/receviedDoc/`);
  } catch (error) {
    return error.response;
  }
};

export const deleteRequestDocs = async (id) => {
  try {
    const response = await api.delete(
      `/task/agent/type/receviedDoc/delete/${id}`
    );
    return response.data; // Assuming the deleted data is returned from the server
  } catch (error) {
    return error.response;
  }
};

export const createRequestDocs = async (task_xid, id, docData) => {
  //console.log((docData);
  try {
    const response = await api.put(
      `/task/client/type/${task_xid}/receviedDoc/${id}/id`,
      docData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    // Handle error or return the error response
    throw error; // Throw the error to be handled by the caller
  }
};




export const createFilledForm = async (task_xid, form_xid, docData) => {
  try {
    const response = await api.put(
      `/task/client/type/${task_xid}/formfill/${form_xid}/id`,
      docData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    // Handle error or return the error response
    throw error; // Throw the error to be handled by the caller
  }
};