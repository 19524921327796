import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRequestDocs } from "../../service/task.service"; // Make sure to import the appropriate service function.

export const fetchRequestDocs = createAsyncThunk(
  "getRequestDocs",
  async (id) => {
    try {
      const res = await getRequestDocs(id);
      return res.data;
    } catch (err) {
      throw new Error(`Error fetching request docs: ${err.message}`);
    }
  }
);

const requestDocsSlice = createSlice({
  name: "request-docs",
  initialState: {
    isrequestDocumentsLoading: false,
    isError: false,
    error: null,
    requestDocuments: [],
  },
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRequestDocs.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(fetchRequestDocs.fulfilled, (state, action) => {
        state.requestDocuments = action.payload?.data || [];
        state.isLoading = false;
      })
      .addCase(fetchRequestDocs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.error.message;
      });
  },
});

export default requestDocsSlice.reducer;
