import React, { useContext, useEffect, useState } from "react";
import "../ChatBox/ChatBox.css";
// import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import ChatBox from "../ChatBox/ChatBox";
import GlobalStateContext from "../../Contexts/GlobalStateContext";
import { Button, Paper, Typography } from "@mui/material";
import Badge from "@mui/material/Badge";
import { Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";
import emptyChatBg from "../../Images/emptyChatBg.png";
import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { createMessage, getMessage } from "../../service/chat.service";
import UserProfile from "../GlobalTable/UserProfile";
import { io } from "socket.io-client";
import TokenService from "../../service/token.service";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

function generateUID() {
  const timestamp = new Date().getTime().toString(36); // Convert current timestamp to base36
  const randomNumber = Math.random().toString(36).substring(2, 10); // Generate a random number and convert to base36
  return timestamp + randomNumber; // Combine timestamp and random number
}

const ChatsRight = ({
  chatContacts,
  activeChatIndex,
  handleOpenNewChat,
  openNewChat,
  handleCloseNewChat,
  contacts,
  chatActiveId,
  message,
  setMessage,
  socket,
  themeColorsObject,
  setIsTyping,
  isTyping,
  isOnline,
}) => {
  const {
    individualArray,
    setIndividualArray,
    selectedChatObject,
    chatActiveIndex,
  } = useContext(GlobalStateContext);

  const [sentMessage, setSentMessage] = useState("");
  // //console.log((activeChatIndex);

  const handleSend = async () => {
    // const uid = generateUID();
    // const currentTime = new Date().toLocaleTimeString([], {
    //   hour: "2-digit",
    //   minute: "2-digit",
    //   hour12: true,
    // });

    // const sent = {
    //   id: uid,
    //   sender: true,
    //   message: text,
    //   time: currentTime,
    // };

    // const updatedIndividualArray = [...individualArray];

    // if (chatActiveIndex !== -1) {
    //   const updatedChats = [...updatedIndividualArray[chatActiveIndex].chats];

    //   updatedChats.push(sent);

    //   updatedIndividualArray[chatActiveIndex].chats = updatedChats;

    //   setIndividualArray(updatedIndividualArray);
    // }
    //console.log(();

    if (sentMessage) {
      const formData = new FormData();
      formData.append("content", sentMessage);

      try {
        const res = await createMessage(chatActiveId, formData); // Assuming getAgent is a function that fetches agent data
        //console.log((res?.data?.payload);
        setMessage(res?.data?.payload);
      } catch (error) {
        // Handle errors here
        console.error("Error fetching agent data:", error);
      }
    }

    setSentMessage("");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  async function fetchData(id) {
    try {
      const res = await getMessage(id); // Assuming getAgent is a function that fetches agent data
      setMessage(res?.data?.payload);
    } catch (error) {
      // Handle errors here
      console.error("Error fetching agent data:", error);
    }
  }

  useEffect(() => {
    fetchData(chatActiveId); // Call the async function

    const token = TokenService.getLocalAccessToken();
    const newSocket = io(process.env.REACT_APP_DOWLOAD_URL, {
      withCredentials: false,
      auth: { token },
    });

    newSocket.on("typing", (data) => {
      //console.log(("typing");
      //console.log((data?.payload);
    });
  }, [io]);

  const handleTyping = () => {
    // Emit a typing event when the user starts typing
    socket.emit("typing", chatActiveId);
  };

  useEffect(() => {
    if (socket) {
      // Listen for typing events from other users
      socket.on("typing", () => {
        setIsTyping(true);

        // Set a timeout to stop displaying typing after a certain duration
        setTimeout(() => {
          setIsTyping(false);
        }, 3000); // Adjust the duration as needed
      });
    }
  }, [setIsTyping, socket]);

  return (
    <div className="row m-0  flex-lg-nowrap overflow-hidden h-100">
      {activeChatIndex === null ? (
        <div className="w-100 h-100 d-flex justify-content-center gap-2 align-items-center flex-column">
          <img width={260} src={emptyChatBg} alt="" />
          <span className="cad-text-large cad-fw-600 mt-3">
            Your chats will show up here.
          </span>
          <span className="cad-text-large text-secondary cad-fw-500 w-50 text-center">
            Get started by tapping the new chat button here or on someone's
            profile
          </span>

          <Button
            onClick={handleOpenNewChat}
            variant="contained"
            className="mt-2"
            style={{ width: "30%", backgroundColor: "" }}
          >
            <AddCircleOutlineIcon className="me-2" /> Start Chatting
          </Button>
        </div>
      ) : (
        <div className="col-lg-12 h-100 col-12 p-0 pb-5 position-relative">
          <div
            className="profile  border-bottom p-2 ps-3 d-flex gap-2"
            style={{
              height:"12%"
            }}
          >
            <div
              style={{ position: "relative" }}
              className="d-flex align-items-start flex-column justify-content-center"
            >
              {/* {isOnline ? ( */}

              {isOnline ? (
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                >
                  <UserProfile proPic={contacts[activeChatIndex]?.profile} />
                </StyledBadge>
              ) : (
                <UserProfile proPic={contacts[activeChatIndex]?.profile} />
              )}
            </div>

            <div className="  d-flex align-items-start flex-column justify-content-center">
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
                variant="h6"
              >
                {contacts[activeChatIndex]?.name}
              </Typography>
              <Typography
                variant="p"
                style={{ fontSize: "12px", color: "#4A5057" }}
              >
                   <div class={`bubble mb-3 ${isTyping ? "d-flex" : "d-none"}`}>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                  </div>
                  
              </Typography>
            </div>
          </div>

          <ChatBox
            isTyping={isTyping}
            chatContacts={chatContacts}
            activeChatIndex={activeChatIndex}
            chatArray={message}
          />

          <div className="chat-input pt-2 ">
            <input
              type="text"
              className={`form-control cad-text-medium w-100 p-3 ps-3 pe-5 ${themeColorsObject.themed_input_class}`}
              placeholder="Message..."
              value={sentMessage}
              onChange={(e) => setSentMessage(e.target.value)}
              onKeyDown={handleKeyPress}
              maxLength={1000}
              onInput={handleTyping}
            />
            <h6 onClick={handleSend} className="chat-sentBtn cad-fw-700">
              send
            </h6>
            {/* <SendOutlinedIcon onClick={handleSend} className="chat-sentBtn" /> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatsRight;
