import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';


export const nav = [
  {
    title: "Tasks",
    path: "/",
    icon: <ListAltOutlinedIcon />,
  },
  {
    title: "Chats",
    path: "/chats",
    icon: <MessageOutlinedIcon />,
    
  },
];
