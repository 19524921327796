import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { authMe, authMeService } from "../../service/auth.service";

export const fetchAuthMeData = createAsyncThunk("fetchAuthMe", async () => {
    try {
        const res = await authMeService(); // Replace with your authentication service function
        return res.data;
    } catch (err) {
        throw new Error(`Error fetching authentication data: ${err.message}`);
    }
});

const authMeSlice = createSlice({
    name: "auth-me",
    initialState: {
        isLoading: false,
        isError: false,
        error: null,
        authInfo: null,
    },
    reducers: {
        // You can add additional reducers if needed
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAuthMeData.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.error = null;
            })
            .addCase(fetchAuthMeData.fulfilled, (state, action) => {
                state.authInfo = action.payload?.data || null;
                state.isLoading = false;
            })
            .addCase(fetchAuthMeData.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.error = action.error.message;
            });
    },
});

export default authMeSlice.reducer;
export const { /* Add any additional actions if needed */ } = authMeSlice.actions;
