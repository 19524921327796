import React, { useState } from "react";
import * as Yup from 'yup'
import { set, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, CircularProgress} from "@mui/material";
import {forgotPasswordCodes} from "../../Utils/StatusCode/index"
import { login } from "../../service/auth.service";
import { useNavigate } from "react-router-dom";
import { ToastContainer, Zoom, toast } from "react-toastify";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';



const PasswordField = ({ setStatus , setOpen, email, }) => {
  const [isPasswordLoading, setIsPasswordLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false);


  let navigate = useNavigate()

  const passwordSchema = Yup.object().shape({

    password: Yup.string()
    .required('Password is required')
    .matches(/[A-Z]/, 'Password must contain atleast one uppercase')
    .matches(/[a-z]/, 'Password must contain atleast one lowercase')
    // .matches(/[@#&]/, 'Password must contain special character @,#,&')
    .min(8, 'password must be at least 8 characters'),
  })

  const {
    register: passwordRegister,
    handleSubmit: handlePasswordSubmit,
    formState: { errors: passErrors }, // Changed from passwordFormState to formState
    reset: resetPassword,
  } = useForm({ mode: 'all', resolver: yupResolver(passwordSchema) })


  const onConfirmPasswordSubmit = async(value) => {
    setIsPasswordLoading(true)

    const credentials = {
      email_address: email,
      password: value.password
    }
    const {status} = await login(credentials)

    if(status === forgotPasswordCodes.STATUS_SUCCESS){
      navigate("/")
      setIsPasswordLoading(false)
      setStatus(forgotPasswordCodes.STATUS_SUCCESS) 
      // //console.log((value);
      resetPassword()
    }else if(status === 401){
      toast.error("Wrong Password");
      setIsPasswordLoading(false)
      setStatus(status)
    resetPassword()
    }

  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle the state to show/hide password
  };



  return (
    
    <form
    onSubmit={handlePasswordSubmit(onConfirmPasswordSubmit)}
    className={`d-flex flex-column  justify-content-start h-100`}
  >
     <div className="mb-2">
      <label
        htmlFor="createPassword1"
        className="form-label text-primary"
      >
       Enter Password
      </label>
      
      <div className="input-group">
      <input
          maxLength={16} // Set the maximum character limit to 16 characters
          type={showPassword ? 'text' : 'password'}
        style={{
          padding:"12px"
        }}
        className="form-control ps-3 border-primary "
        id="createPassword1"
        placeholder="Enter password"
        {...passwordRegister("password")}
      />
      
      <button
            className="btn btn-outline-primary "
            type="button"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <VisibilityOffIcon className="cad-text-large" /> : <RemoveRedEyeIcon className="cad-text-large" />}
          </button>
          </div>
      <label
        htmlFor="exampleInputPassword1"
        className="form-label text-primary form-text text-danger"
      >
        {passErrors.password?.message}
      </label>
    </div> 

    <Button
      type="submit"
      className={`${isPasswordLoading ? "opacity-75" : ""} btn pt-2 fs-5 cad-fw-700 pb-2 button-contained`}>
      {isPasswordLoading ? <CircularProgress size={36} color="inherit" /> :
      "Login"}
    </Button>
  </form>
  )
}

export default PasswordField