import { Avatar } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import LightTooltip from "../Tooltip/LightTooltip";

const NotificationAll = ({data}) => {
  console.log(data);


  return (
    <>
      {data?.map((item, index) => (
        
        <LightTooltip
        color="#e74c3c"
        title={item?.title}
      >
        <div className="pt-3 position-relative pb-3 align-items-start gap-3 d-flex border-bottom">
          
          <Avatar
            alt="Remy Sharp"
          src={item?.banner}
          />
          <span  className="col-8 pointer  d-flex flex-column justify-content-center">
            <span className="cad-text-small fw-bold text-truncate">{item?.title}</span>
            <span className="cad-text-small text-secondary">{item?.content}</span>
          </span>

          <span 
          className="cad-text-small "
          style={{
            position:"absolute",
            right:0,
            top:4
          }}>4:00 pm</span>
        </div></LightTooltip>
      ))}
    </>
  );
};

export default NotificationAll;
