import { Avatar } from "@mui/material";
import React from "react";
import dummyLogo from "../../Images/dummyLogo.png"

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}`,
  };
}

// ProfilePic component displays a user's profile picture and name
const UserProfile = ({ proPic, companyName }) => {

  
  let picUrl = proPic === null ? dummyLogo : process.env.REACT_APP_DOWLOAD_URL+"/"+proPic
  // className="text-truncate"
  return (
    <span  style={{ display: "flex", alignItems: "center", gap:10  }}>
      <Avatar
        sx={{ width: 40, height: 40, borderRadius: "50%"}}
        // alt={companyName}
        src={picUrl}
        {...stringAvatar(`${companyName} `)}
      />
      {companyName} {/* Display the user's name */}
    </span>
  );
};

export default UserProfile;
