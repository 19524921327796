import React, { useRef, useState } from "react";
import FormCanvas from "./FormCanvas";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addNewForm } from "../../Redux/Slice/getFormsSlice";
import { createFilledForm } from "../../service/task.service";
import { useNavigate, useParams } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { notify } from "../../App";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const FillForm = () => {
  const pdfRef = useRef();
  const navigate = useNavigate();
  const [formAttachment, setFormAttachment] = useState([]);
  const { id1, id2 } = useParams();
  const [openPreviwModal, setOpenPreviewModal] = useState(false);
  const [pdfFilledLoading, setPdfFilledLoading] = useState(false);
  const [isPdfLoading, setPdfLoading] = useState()
  const { taskById, isLoading } = useSelector((state) => state?.tasks);
  const { forms } = taskById;
  const [formData, setFormData] = useState(() => {
    if (forms && forms.length > 0) {
      const firstForm = forms[0];
      if (typeof firstForm?.formData === "string") {
        try {
          return JSON.parse(firstForm.formData);
        } catch (e) {
          console.error("Error parsing formData:", e);
          return firstForm.formData; // fallback to raw formData in case of parse error
        }
      } else {
        return firstForm.formData;
      }
    }
    return []; // or any default value you want
  });
  const createGradientCanvas = (width, height) => {
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");

    // Create gradient
    const gradient = ctx.createLinearGradient(0, 0, width, height);
    gradient.addColorStop(0, "#fff");
    gradient.addColorStop(1, "#fff");

    // Fill with gradient
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, width, height);

    return canvas;
  };
  const handlePdfDownload = () => {
    setPdfLoading(true);
    const input = pdfRef.current;
  
    if (!input) {
      console.error("pdfRef is not correctly assigned or does not exist.");
      setPdfLoading(false);
      return;
    }
  
    html2canvas(input, { scale: 3 })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/jpeg', 0.95);
  
        const pdf = new jsPDF('p', 'pt', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const margin = 10; // Define a margin
  
        const imgProps = pdf.getImageProperties(imgData);
        const imgHeight = (pdfWidth * imgProps.height) / imgProps.width;
        let heightLeft = imgHeight;
        let position = 0;
  
        while (heightLeft > 0) {
          pdf.addImage(imgData, 'JPEG', margin, position + margin, pdfWidth - 2 * margin, imgHeight - 2 * margin);
          heightLeft -= pdfHeight - 2 * margin;
          position -= pdfHeight - 2 * margin;
  
          if (heightLeft > 0) {
            pdf.addPage();
          }
        }
  
        pdf.save('cloudify.pdf');
        setPdfLoading(false);
      })
      .catch((error) => {
        console.error("Error generating PDF: ", error);
        setPdfLoading(false);
      });
  };
  



  const sendFilledPdfForm = async () => {
    setPdfFilledLoading(true);
    const input = pdfRef.current;

    // Generate the canvas and convert it to an image
    const canvas = await html2canvas(input, { scale: 3 }); // Increased scale for better quality
    const imgData = canvas.toDataURL('image/jpeg', 0.95); // High quality JPEG

    const pdf = new jsPDF('p', 'pt', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    // Calculate the height of the content and determine the number of pages needed
    const imgProps = pdf.getImageProperties(imgData);
    const imgHeight = (pdfWidth * imgProps.height) / imgProps.width;
    let heightLeft = imgHeight;
    let position = 0;

    // Add the content image
    while (heightLeft > 0) {
      pdf.addImage(imgData, 'JPEG', 0, position, pdfWidth, imgHeight);
      heightLeft -= pdfHeight;
      position -= pdfHeight;

      // Add a new page if there is still content left
      if (heightLeft > 0) {
        pdf.addPage();
      }
    }

    // Generate PDF and get its data
    const pdfData = pdf.output('blob');


    const convertArrayToFileList = (fileArray) => {
      const dataTransfer = new DataTransfer();
    
      fileArray.forEach((file) => {
        dataTransfer.items.add(file);
      });
    
      return dataTransfer.files;
    };

  

    const sendFilledForm = async (pdfBlob) => {
      console.log(formAttachment);
      const formData = new FormData();
      const jsonData = JSON.stringify(formDataObject);
      formData.append("formData", jsonData);
      formData.append("attachment", pdfBlob, 'cloudify.pdf');
      // formData.append(`documents`, convertArrayToFileList(formAttachment));
      Array.isArray(formAttachment) && formAttachment.map((items, index) => formData.append(`documents`, items))
      // for (let i = 0; i < formAttachment?.length; i++) {
      //   formData.append(`documents[${i}]`, formAttachment[i]);
      // }

      try {
        const response = await createFilledForm(id1, id2, formData);
        notify("Form sent");
        navigate("/");
        return response;
      } catch (error) {
        console.error(error);
      }
    };

    // Call the function to send the form with the generated PDF
    await sendFilledForm(pdfData);

    setPdfFilledLoading(false);
  };

  const nonInputData = formData?.filter(
    (field) => field.type === "header"
  );
  const [formDataObject, setFormDataObject] = useState(nonInputData);

  return (
    <div
      style={{
        backgroundColor: formData[0]?.backgroundColor,
      }}
      className="w-100 rounded-3 position-relative h-100 d-flex justify-content-center overflow-auto"
    >
      {isLoading ? (
        "Loding"
      ) : (
        <FormCanvas
          setFormAttachment={setFormAttachment}
          formAttachment={formAttachment}
          pdfRef={pdfRef}
          id1={id1}
          id2={id2}
          openPreviwModal={openPreviwModal}
          setOpenPreviewModal={setOpenPreviewModal}
          formDataObject={formDataObject}
          setFormDataObject={setFormDataObject}
          formData={formData}
          setFormData={setFormData}
        // handleSubmit={handleSubmit}
        />
      )}

      {/* <span
        onClick={sendFilledForm}
        className="cad-text-medium cad-fw-500 pointer d-flex align-items-center gap-2"
        style={{
          position: "absolute",
          right: 26,
          top: 16,
          backgroundColor: "purple",
          padding: "6px 16px",
          color: "#fff",
          borderRadius: "6px",
        }}
      >
        Send <SendIcon className="cad-text-large cad-fw-500" />
      </span> */}


      <span
        onClick={sendFilledPdfForm}
        className="cad-text-medium cad-fw-500 pointer d-flex align-items-center gap-2"
        style={{
          position: "absolute",
          right: 26,
          top: 16,
          backgroundColor: "purple",
          padding: "6px 16px",
          color: "#fff",
          borderRadius: "6px",
        }}
      >
        {pdfFilledLoading ? "Loading..." : "Send"} <SendIcon className="cad-text-large cad-fw-500" />
      </span>

      <span
        onClick={handlePdfDownload}
        className="cad-text-medium cad-fw-500 pointer d-flex align-items-center gap-2"
        style={{
          position: "absolute",
          right: 26,
          top: 66,
          backgroundColor: "#E12929",
          padding: "6px 16px",
          color: "#fff",
          borderRadius: "6px",
        }}
      >
        {isPdfLoading ? "Loading..." : "Download Pdf"} <PictureAsPdfIcon className="cad-text-large cad-fw-500" />
      </span>


    </div>
  );
};

export default FillForm;
