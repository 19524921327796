import api from './api.service'
let formDataConfig = {
    headers: {
        "Content-Type": "multipart/form-data",
    },
};

export const createPersonalInfo = async (data) => {
    //console.log((data);
    try {
        return await api.put(`/onBoard/client/onBoard-contact`, data,)
    } catch (error) {
        return error.response
    }
}


export const createContactInfo = async (data) => {
    //console.log((data);
    try {
        return await api.put(`/onBoard/client/onBoard-contact`, data,)
    } catch (error) {
        return error.response
    }
}


export const createNationalityInfo = async (data) => {
    //console.log((data);
    try {
        return await api.put(`/onBoard/client/nationalityInfo`, data,)
    } catch (error) {
        return error.response
    }
}