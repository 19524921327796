import React, { useEffect } from "react";
import { Paper } from "@mui/material";
import "./Task.css"; // Importing styles
import TaskGridView from "./TaskGridView/TaskGridView"; // Importing TaskGridView component
import { Box } from "@mui/system";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import {fetchAuthMeData} from "../../Redux/Slice/fetchAuthMe";
import { useSelector } from "react-redux";
import * as themeColors from "../../Utils/Theme/Colors";
import kanbanSlice, { fetchKanbanData } from "../../Redux/Slice/kanbanSlice";
import { ToastContainer } from "react-toastify";



const Task = () => {
  const {theme , themeColorsObject } = useSelector((state) => state.theme);

  
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchAuthMeData())
    dispatch(fetchKanbanData())
  }, [])
  
  return (
    <div
    // className=" bg-danger"
    style={{
     height:"87vh",
     width:"100%"

   }}>
          <TaskGridView themeColorsObject={themeColorsObject} theme={theme} />
          
      </div>
  );
};

export default Task;
