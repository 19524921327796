import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Badge,
  Button,
  Fade,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import { Route, Routes } from "react-router";
import { RouteLink } from "../_routes";
import NotFound from "../Pages/NotFound/NotFound";
import { nav } from "../_nav";
import SlideBtn from "./SubComponents/SlideBtn/SlideBtn";
import Accordion from "./SubComponents/Accordion";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LogoutTwoToneIcon from "@mui/icons-material/LogoutTwoTone";
import NotificationAll from "./NotificationAll/NotificationAll";
import Alerts from "./Alerts/Alerts";
import Message from "./Message/Message";
import { BiBell } from "react-icons/bi";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { useDispatch } from "react-redux";
import CustomizedBreadcrumbs from "./CustomizedBreadcrumbs.js";
import SettingTab from "./SettingTab/SettingTab.js";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import AlertModal from "./AlertModal.js";
import MainModal from "./MainModal.js";
import { useEffect } from "react";
import * as Yup from "yup";
import Cookies from "js-cookie";
import { getNotificationData } from "../Redux/Slice/notification.service.slice.js";
import { createPersonalInfo } from "../service/onboard.service.js";
import { notify } from "../App.js";

const drawerWidth = 260;

const clientOnboardingSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('First name is required.'),
  lastName: Yup.string()
    .required('Last name is required.'),
  contactNumber: Yup.string()
    .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, 'Invalid phone number format. Include country code.'),
  emailAddress: Yup.string()
    .email('Invalid email address.')
    .required('Email address is required.'),
  address: Yup.string()
    .required('Address is required.'),
  city: Yup.string()
    .required('City is required.'),
  state: Yup.string()
    .required('State is required.'),
  postalCode: Yup.string()
    .required('Postal code is required.'),
  country: Yup.string()
    .required('Country is required.'),
});


const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function NewDrawerPersistace() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const storedActiveStep = Cookies.get("activeStep");
    if (storedActiveStep !== undefined) {
      setActiveStep(parseInt(storedActiveStep));
    }
    if (Cookies.get("welcome") && parseInt(storedActiveStep) === 4) {
      setWelcomeModal(false);
      setIsProfileForm(false);
    } else if (Cookies.get("welcome") && parseInt(storedActiveStep) !== 4) {
      setWelcomeModal(false);
      setIsProfileForm(true);
    } else {
      setIsProfileForm(false);
      setWelcomeModal(true);
    }
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const [avatarPopoverOpen, setAvatarPopoverOpen] = useState(false);
  const [helloPopperOpen, setHelloPopperOpen] = useState(false);
  const [open, setOpen] = useState(true);
  const [openLogOut, setOpenLogOut] = useState(false);
  const [activeButton, setActiveButton] = useState("All");
  const [isSettingOpen, setIsSettingOpen] = useState(false);
  const [isProfileForm, setIsProfileForm] = useState(false);
  const [welcomeModal, setWelcomeModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const rolbase = useSelector((state) => state?.authMe);
  const { authInfo } = useSelector((state) => state?.fetchAuthMe);
  const profileData = authInfo;
  const { themeColorsObject } = useSelector((state) => state.theme);



  // Function to get the title based on the route
  const getTitle = () => {
    switch (path) {
      case "/task":
        return "Task";
      case "/contacts":
        return "Contacts";
      case "/form":
        return "Form";
      case "/chats":
        return "Chats";
      case "/settings":
        return "Settings";
      case "/support":
        return "Setting » Support";
      case "/users":
        return "Setting » Users";
      case "/subscription":
        return "Setting » Subscription";
      case "/tags":
        return "Setting » Tags";
      case "/company-branding":
        return "Setting » Company Branding";
      case "/accounts-security":
        return "Setting » Accounts & Security";
      case "/notification":
        return "Setting » Notifications";
      case "/upgrade-plan":
        return "Upgrade Plan";
      case "/profile":
        return "Profile";
      case "/add-task":
        return "Task » Add task";
      case "/add-new-user":
        return "Setting » Users » Add User";
      default:
        return "Dashboard";
    }
  };

  const handleOpen = () => {
    setOpenLogOut(true);
    setAvatarPopoverOpen(false); // Close the other Popper
  };

  const closeLogout = () => {
    setOpenLogOut(false);
    setAvatarPopoverOpen(false); // Close the other Popper
  };

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
    setAvatarPopoverOpen(!avatarPopoverOpen);
    setHelloPopperOpen(false); // Close the other Popper
  };

  const handleCloseAvatarPopover = () => {
    setAvatarPopoverOpen(false);
  };

  const handleBellClick = (event) => {
    setAnchorEl(event.currentTarget);
    setHelloPopperOpen(!helloPopperOpen);
    setAvatarPopoverOpen(false); // Close the other Popper
  };

  const handleProfileFormModal = () => {
    setIsProfileForm(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      Cookies.set("activeStep", prevActiveStep + 1, { expires: 365 }); // Set cookie to expire in one year
      return prevActiveStep + 1;
    });
  };

  const handleSkip = () => {
    Cookies.set("welcome", true, {
      expires: 365,
      sameSite: "None",
      secure: true,
    });
    setWelcomeModal(false);
    setIsProfileForm(false);
  };

  const handleContinue = () => {
    Cookies.set("welcome", true, {
      expires: 365,
      sameSite: "None",
      secure: true,
    });
    setWelcomeModal(false);
    setIsProfileForm(true);
  };

  const { notification, notification_count } = useSelector((state) => state?.notification);
  // console.log(notification);
  // useEffect hook to call the fetchNotification function initially and then every 10 seconds
  useEffect(() => {
    // Interval to call fetchNotification every 10 seconds
    const interval = setInterval(() => {
      dispatch(getNotificationData());
    }, 10000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [dispatch]);

  // console.log(profileData?.business[0]?.business_logo);


  const inputFields = [
    { name: "firstName", label: "First Name", type: "text", required: true },
    { name: "lastName", label: "Last Name", type: "text", required: true },
    { name: "contactNumber", label: "Contact Number", type: "text", required: true },
    { name: "emailAddress", label: "Email Address", type: "text", required: true },
    { name: "address", label: "Address", type: "text", required: true },
    { name: "city", label: "City", type: "text", required: true },
    { name: "state", label: "Province/State", type: "text", required: false },
    { name: "postalCode", label: "Postal/Zip Code", type: "text", required: false },
    { name: "country", label: "Country ", type: "text", required: true },
  ];

  const [formData, setFormData] = useState({
    firstName: "",
    prefilastNamex: "",
    contactNumber: "",
    emailAddress: "",
    address: "",
    state: "",
    postalCode: "",
    country: "",
  });

  const [errors, setErrors] = useState({});

  const validateField = async (field, value) => {
    try {
      await clientOnboardingSchema.validateAt(field, { [field]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
    } catch (error) {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: error.message }));
    }
  };



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    validateField(name, value);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    clientOnboardingSchema
      .validate(formData, { abortEarly: false })
      .then(async () => {
        try {
          const res = await createPersonalInfo(formData);
          if (res?.status === 500) {
            notify(res?.data?.error?.message, "error");
          } else {
            handleNext();
            notify("Personal Information Added Successfully", "success");
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          console.log(error);
        }
        setFormData({
          suffix: "",
          prefix: "",
          given_name: "",
          surname: "",
          civil_status: "",
          date_of_birth: "",
          sex: "",
        });
        setErrors({});
        setIsProfileForm(false)
      })
      .catch((error) => {
        setIsLoading(false);
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      });

  };

  return (
    <Box sx={{ display: "flex" }}>
      {/* <CssBaseline /> */}

      <AppBar
        className={`${themeColorsObject.app_bar_class} appbar-shadow `}
        style={{
          backgroundColor: themeColorsObject.megaCardBackgroundColor,
        }}
        position="fixed"
        open={open}
      >
        <span className="d-flex justify-content-between ps-4 pe-2 p-2">
          <div className="d-flex align-items-center">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(!open)}
              edge="start"
              className="rounded-2"
              sx={{ mr: 2, ...(open && { display: "" }) }}
            >
              {open ? (
                <MenuOpenIcon style={{ fontSize: 26 }} />
              ) : (
                <MenuIcon style={{ fontSize: 26 }} />
              )}
            </IconButton>

            <span
              style={{
                borderStartStartRadius: "2%",
                borderBottomLeftRadius: "2%",
              }}
              className="overflow-hidden d-flex"
            >
              {profileData?.business[0]?.business_logo && profileData?.business[0]?.subscriptions[0]?.package?.domain ? (
                <img
                  alt="business logo"
                  style={{
                    // opacity: open ? 0 : 1,
                    transform: open ? "translateX(-180px)" : "translateX(0px)",
                    transition: "0.5s",
                  }}
                  width={150}
                  height={50}
                  src={`${process.env.REACT_APP_DOWLOAD_URL}/${profileData?.business[0]?.business_logo}`}
                />
              ) : (
                <>
                  <span
                    className="cad-fw-700 d-flex align-items-end"
                    style={{
                      color: "#4CCD8D",
                      fontSize: "28px",
                      // opacity: open ? 0 : 1,
                      transform: open
                        ? "translateX(-140px)"
                        : "translateX(0px)",
                      transition: "0.5s",
                    }}
                  >
                    Cloudify
                  </span>
                  <span
                    className="text-primary d-flex align-items-end cad-fw-500"
                    style={{
                      fontSize: "26px",
                      // opacity: open ? 0 : 1,
                      transform: open
                        ? "translateX(-200px)"
                        : "translateX(0px)",
                      transition: "0.8s",
                    }}
                  >
                    Docs
                  </span>
                </>
              )}
            </span>

            {/* <Typography variant="h6" noWrap component="div">
              {title}
            </Typography> */}
          </div>

          <div className="header-right  pe-1 d-flex align-items-center gap-3">
            <div>
              <Tooltip title="Profile Pic">
                <div className="d-flex gap-3 align-items-center">
                  <Avatar
                    alt={profileData?.first_name}
                    src={
                      process.env.REACT_APP_DOWLOAD_URL +
                      "/" +
                      profileData?.profile_photo
                    }
                    onClick={handleAvatarClick} // Open popper when avatar is clicked
                    className="pointer" // Make the avatar clickable
                  />

                  <div className="d-flex flex-column me-2">
                    <span className="cad-fw-600 text- cad-text-medium">
                      Hello, {profileData?.first_name} {profileData?.last_name}
                    </span>
                    <span className="cad-text-700 text-primary cad-text-small ">
                      {profileData?.email_address}
                    </span>
                  </div>
                </div>
              </Tooltip>
              <Popper
                open={avatarPopoverOpen}
                anchorEl={anchorEl}
                placement="bottom-end"
                transition
                sx={{ zIndex: 1200 }}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper
                      className="m-2"
                      style={{
                        width: "220px",
                        backgroundColor:
                          themeColorsObject.megaCardBackgroundColor,
                      }}
                    >
                      <Link
                        onClick={handleCloseAvatarPopover}
                        to="/profile"
                        className="d-flex text-decoration-none p-3 pointer align-items-center gap-3"
                      >
                        <Avatar
                          alt={profileData?.first_name}
                          src={
                            process.env.REACT_APP_DOWLOAD_URL +
                            "/" +
                            profileData?.profile_photo
                          }
                          style={{
                            width: "30px",
                            height: "30px",
                          }}
                        />
                        <Typography
                          sx={{
                            fontWeight: "400",
                            color: themeColorsObject.textColor,
                          }}
                          variant="subtitle2"
                        >
                          Profile
                        </Typography>
                      </Link>

                      <Link className="d-flex text-decoration-none border-top  p-3 pointer align-items-center gap-3">
                        <LogoutTwoToneIcon
                          style={{
                            width: "26px",
                            height: "26px",
                          }}
                        />

                        <Typography
                          onClick={handleOpen}
                          sx={{
                            fontWeight: "400",
                            color: themeColorsObject.textColor,
                          }}
                          variant="subtitle2"
                        >
                          Logout
                        </Typography>
                      </Link>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </div>

            <div>
              <Tooltip title="Notifcation">
                <Badge
                  onClick={handleBellClick}
                  className="pointer"
                  badgeContent={notification_count}
                  color="error"
                >
                  <BiBell size={22} className="bell cad-fw-800" />
                </Badge>
              </Tooltip>
              <Popper
                open={helloPopperOpen}
                anchorEl={anchorEl}
                placement="bottom-end"
                transition
                sx={{ zIndex: 1200 }}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={0}>
                    <div
                      className="m-2"
                      style={{
                        width: "330px",
                      }}
                    >
                      <Paper
                        elevation={2}
                        style={{
                          backgroundColor: "#548CFF",
                        }}
                        className="row pt-3"
                      >
                        <div className="col-7 d-flex align-items-center">
                          <Typography
                            style={{
                              fontSize: "18px",
                              color: "#ffffff",
                            }}
                            variant="h6"
                          >
                            Notifications
                          </Typography>
                        </div>


                        <div className="col-5 d-flex align-items-center justify-content-end">
                          <Paper
                            style={{
                              fontSize: "12px",
                            }}
                            className="fw-bold p-1 ps-2 pe-2"
                          >
                            {notification_count} New
                          </Paper>
                        </div>

                        <div className="mt-3 d-flex justify-content-start"></div>
                        <div
                          className="pb-3"
                          style={{
                            backgroundColor:
                              themeColorsObject.megaCardBackgroundColor,
                            borderRadius: "0px 0px 6px 6px",
                            color: themeColorsObject.textColor,
                            height: "60vh",
                            overflow: "scroll",
                          }}
                        >
                          {activeButton === "All" ? (
                            <NotificationAll data={notification} />
                          ) : activeButton === "Alerts" ? (
                            <Alerts />
                          ) : (
                            <Message />
                          )}
                        </div>
                      </Paper>
                    </div>
                  </Fade>
                )}
              </Popper>
            </div>
            <div
              onClick={() => setIsSettingOpen(!isSettingOpen)}
              className="rounded-2 p-1 me-2 theme-button"
            >
              <Tooltip title="Setting">
                <SettingsRoundedIcon className="pointer text-primary sun cad-text-xlarge" />
              </Tooltip>
            </div>
          </div>
        </span>

        <AlertModal
          handler={() => {
            navigate("/login");
            localStorage.removeItem("user");
          }}
          open={openLogOut}
          closeModal={closeLogout}
          message={"Are You sure you want to logout?"}
        />
        <SettingTab
          isSettingOpen={isSettingOpen}
          setIsSettingOpen={setIsSettingOpen}
        />
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: themeColorsObject.megaCardBackgroundColor,
            color: themeColorsObject.textColor,
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader className="w-100 d-flex  justify-content-center gap-2">
          {/* <span
            className="cad-fw-700 d-flex align-items-end"
            style={{ color: "#4CCD8D", fontSize: "30px" }}
          >
            CAD
          </span>
          <span
            className="text-primary d-flex align-items-end cad-fw-500"
            style={{ fontSize: "28px" }}
          >
            ITSolution
          </span> */}

          <span
            style={{
              borderStartStartRadius: "2%",
              borderBottomLeftRadius: "2%",
            }}
            className="overflow-hidden d-flex"
          >
            {profileData?.business[0]?.business_logo && profileData?.business[0]?.subscriptions[0]?.package?.domain ? (
              <img
                style={{
                  // opacity: open ? 0 : 1,
                  transform: open ? "translateX(0px)" : "translateX(140px)",
                  transition: "0.5s",
                }}
                width={150}
                height={50}
                src={`${process.env.REACT_APP_DOWLOAD_URL}/${profileData?.business[0]?.business_logo}`}
              />
            ) : (
              <>
                <span
                  className="cad-fw-700 d-flex align-items-end"
                  style={{
                    color: "#4CCD8D",
                    fontSize: "28px",
                    // opacity: open ? 0 : 1,
                    transform: open ? "translateX(0px)" : "translateX(140px)",
                    transition: "0.5s",
                  }}
                >
                  Cloudify
                </span>
                <span
                  className="text-primary d-flex align-items-end cad-fw-500"
                  style={{
                    fontSize: "26px",
                    // opacity: open ? 0 : 1,
                    transform: open ? "translateX(0px)" : "translateX(200px)",
                    transition: "0.8s",
                  }}
                >
                  Docs
                </span>
              </>
            )}
          </span>
        </DrawerHeader>

        {/* <Divider /> */}

        <div className="d-flex flex-column h-100 p-2 gap-2 pt-0 justify-content-between">
          {rolbase?.resources?.length === 0 ? (
            <List>
              {nav.map(({ title, path, icon, items }, index) =>
                items ? (
                  <ListItem className=" mb-4" key={title} disablePadding>
                    <Accordion
                      themeColorsObject={themeColorsObject}
                      dropLinks={items}
                      title={title} // Corrected 'link.title'
                      size={23}
                      Icon={icon} // Corrected 'link.icon'
                    />
                  </ListItem>
                ) : (
                  <ListItem className=" " key={title} disablePadding>
                    <SlideBtn
                      themeColorsObject={themeColorsObject}
                      title={title}
                      path={path}
                      size={22}
                      Icon={icon}
                    />
                  </ListItem>
                )
              )}
            </List>
          ) : (
            <List>
              {nav.map(({ title, path, icon, items }, index) =>
                items ? (
                  <ListItem className=" mb-4" key={title}>
                    <Accordion
                      themeColorsObject={themeColorsObject}
                      dropLinks={items}
                      title={title} // Corrected 'link.title'
                      size={22}
                      Icon={icon} // Corrected 'link.icon'
                    />
                  </ListItem>
                ) : (
                  <ListItem key={title} disablePadding>
                    <SlideBtn
                      themeColorsObject={themeColorsObject}
                      title={title}
                      path={path}
                      size={22}
                      Icon={icon}
                    />
                  </ListItem>
                )
              )}
            </List>
          )}
        </div>
      </Drawer>
      <Main
        onClick={() => setAvatarPopoverOpen(false)}
        style={{
          backgroundColor: themeColorsObject.backgroundColor,
          padding: "56px 10px 24px 10px",
        }}
        open={open}
      >
        {true ? (
          <CustomizedBreadcrumbs route={getTitle()} path={path} />
        ) : (
          <></>
        )}

        <AppContent />

        {/* {path === "/fill-form/:id1/:id2" ? "" : <Footer />} */}
        {/* {path === "/template-forms/new-form" ? "" : ""} */}
      </Main>

      <MainModal
        blur={false}
        title={"Please fill Onboarding Details"}
        width={"50%"}
        open={isProfileForm}
        handleClose={handleProfileFormModal}
      >
        <form className="d-flex mb-4 flex-wrap" onSubmit={handleSubmit}>
          {inputFields.map((field) => (
            <div
              className="col-6 mb-2 p-1 d-flex flex-column gap-2"
              key={field.name}
            >
              <div
                style={{
                  color: themeColorsObject.titleText,
                }}
                className="cad-text-medium cad-fw-500 "
              >
                {field.label} {field?.required ? <span className="text-danger cad-text-small fw-bold">*</span> : ""}
              </div>
              {field.type === "select" ? (
                <select
                  className={`form-select pointer cad-text-medium rounded-2  ${themeColorsObject.themed_input_class}`}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                >
                  {field.options.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  style={{
                    backgroundColor: "",
                    color: themeColorsObject.titleText,
                  }}
                  className={`form-control cad-text-medium rounded-2  ${themeColorsObject.themed_input_class}`}
                  type={field.type}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                />
              )}
              {errors[field.name] && (
                <p
                  className="cad-text-small cad-text-bold"
                  style={{ color: "red" }}
                >
                  {errors[field.name]}
                </p>
              )}
            </div>
          ))}
          <div className="w-100 d-flex justify-content-center">
            <div className="col-3 mt-4">
              <Button
                type="submit"
                className="button-contained"
                variant="contained"
              >
                {isLoading ? "Loading..." : "Submit"}
              </Button>
            </div>
          </div>
        </form>
      </MainModal>

      <MainModal
        blur={true}
        // title={"Please fill Onboarding Details"}
        width={"80%"}
        height={"auto"}
        open={welcomeModal}
        bg="#fff"
        handleClose={handleSkip}
      >
        <div className="w-100 mb-5  d-flex align-items-center flex-column gap-3">
          {" "}
          <div className="d-flex">
            <span
              className="cad-fw-700 d-flex align-items-end"
              style={{
                color: "#4CCD8D",
                fontSize: "36px",
                transition: "0.5s",
              }}
            >
              Cloudify
            </span>
            <span
              className="text-primary d-flex align-items-end cad-fw-500"
              style={{
                fontSize: "32px",
                transition: "0.8s",
              }}
            >
              Docs
            </span>
          </div>
          <span className="display-4 text-center cad-fw-400">
            Welcome, {profileData?.first_name} {profileData?.last_name}
          </span>
          <p className="cad-text-xlarge mt-2">
            Would you please take moment to fill onboarding form
          </p>
          <div className="d-flex gap-3">
            <Button
              onClick={handleSkip}
              variant="conatained"
              className="button-outlined"
            >
              Skip
            </Button>
            <Button
              onClick={handleContinue}
              variant="conatained"
              className="button-contained"
            >
              Continue
            </Button>
          </div>
        </div>
      </MainModal>
    </Box>
  );
}

const AppContent = () => {
  return (
    <Routes>
      {RouteLink.map(({ path, component }, index) => (
        <Route key={index} path={path} element={component} />
      ))}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
