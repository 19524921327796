import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { AiOutlineAppstore } from "react-icons/ai";
import { FaTasks, FaWpforms } from "react-icons/fa";
import { LuContact } from "react-icons/lu";
import { BsChatSquareText } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { motion, useAnimation } from "framer-motion";
import { Button } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useHistory } from 'react-router-dom';


const Accordion = ({ isMenuVisible, Icon, size, title, dropLinks, miniIcons }) => {
  const location = useLocation();

  const [isVisible, setIsVisible] = useState(false);
  const controls = useAnimation();

  const toggleAccordion = async () => {
    setIsVisible(!isVisible);
    if (!isVisible) {
      await controls.start({ height: "auto" });
    } else {
      await controls.start({ height: 0 });
    }
  };

  

  
  

  return (
    <motion.div
      className="d-flex flex-column gap-1"
      initial={{ height: 0 }}
      animate={controls}
      transition={{ duration: 0.1 }}
    >
      <Link to="/support" onClick={toggleAccordion} className="text-decoration-none">
        <Button
          style={{ textTransform: "none", color: "#191D88" }}
          className="slidebtn w-100 p-2 ps-3 pe-3 d-flex align-items-center  justify-content-between"
        >
          <div className="d-flex gap-3 align-items-center">
            {Icon}
            <span
              style={{
                display: isMenuVisible ? "none" : "",
              }}
              className="ms-1  text-medium"
            >
              {title}
            </span>
          </div>
          {isVisible ? (
            <IoIosArrowUp
              style={{
                display: isMenuVisible ? "none" : "",
              }}
            />
          ) : (
            <IoIosArrowDown
              style={{
                display: isMenuVisible ? "none" : "",
              }}
            />
          )}
        </Button>
      </Link>

      { isMenuVisible ? <></>:
      <div
      style={{
        overflow: "hidden",
      }}
    >
      {dropLinks.map((item, index) => (
        <NavLink
          key={index}
          style={{
            textDecoration: "none",
          }}
          to={item.path}
        >
          <Button
            style={{
              textTransform: "none",
              
            }}
            className={`p-2 slidebtn ${
              location.pathname === item.path ? "active" : ""
            } mb-1 ps-3 d-flex align-items-center justify-content-start w-100 `}
          >
            <FiberManualRecordIcon
              style={{
                display: isMenuVisible ? "" : "none",
                fontSize:"6px"
              }}
              size={size}
              className="icons me-0 pt-2 pb-2"
            />

            <span
              style={{
                display: isMenuVisible ? "none" : "",
              }}
              className="ms-4 ps-3 cad-text-small"
            >
              
              {item.subTitle}
            </span>
          </Button>
        </NavLink>
      ))}
      </div>}
    </motion.div>
  );
};

export default Accordion;
