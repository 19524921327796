import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTask, getTaskById } from "../../service/task.service";

// export const fetchTaskData = createAsyncThunk("fetchTaskData", async (params) => {
//   try {
//     const res = await getTask(params);
//     //console.log((res.data);
//     return res.data;
//   } catch (err) {
//     throw new Error(`Error fetching data: ${err.message}`);
//   }
// });


export const fetchTaskDataByID = createAsyncThunk(
  "fetchTaskDataById",
  async (params) => {
    try {
      const res = await getTaskById(params);
      return res.data;
    } catch (err) {
      throw new Error(`Error fetching data by ID: ${err.message}`);
    }
  }
);



const  taskSlice = createSlice({
    name: "fetchTask",
    initialState: {
      isLoading: true,
      isError: false,
      error: null,
      tasks: [], // Add a faq property to store the fetched data
      totalPages: 0,
      taskById: {},
    },
    reducers: {
    },
    extraReducers: (builder) => {
      builder
        // .addCase(fetchTaskData.pending, (state) => {
        //   state.isLoading = true;
        //   state.isError = false;
        //   state.error = null;
        // })
        // .addCase(fetchTaskData.fulfilled, (state, action) => {
        //   state.isLoading = false;
        //   state.tasks = action.payload.data.rows; // Update the faq property with the fetched data
        //   state.totalPages = action.payload.data.totalPages;
        // })
        // .addCase(fetchTaskData.rejected, (state, action) => {
        //   state.isLoading = false;
        //   state.isError = true;
        //   state.error = action.error.message; // Store the error message
        // })
        .addCase(fetchTaskDataByID.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
          state.error = null;
        })
        .addCase(fetchTaskDataByID.fulfilled, (state, action) => {
          state.isLoading = false;
          state.taskById = action.payload.data;
        })
        .addCase(fetchTaskDataByID.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.error = action.error.message;
        });
    },
  });
  
  export default taskSlice.reducer;
  