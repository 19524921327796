import api from './api.service'
let formDataConfig = {
    headers: {
        "Content-Type": "multipart/form-data",
    },
};

export const createOrGetOneOnOneChat = async (reciverId) => {
    //console.log((reciverId);
    try {
        return await api.post(`/chat/c/${reciverId}`)
    } catch (error) {
        return error.response
    }
}

export const getContacts = async () => {
    try {
        return await api.get(`/chat/`)
    } catch (error) {
        return error.response
    }
}

export const createMessage = async (chatId, data) => {
    //console.log((chatId);
    try {
        return await api.post(`/chat/message/${chatId}`, data)
    } catch (error) {
        return error.response
    }
}

export const getMessage = async (chatId) => {
    //console.log((chatId);
    try {
        return await api.get(`/chat/message/${chatId}`)
    } catch (error) {
        return error.response
    }
}
