import React from "react";
import { Link } from "react-router-dom";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Button } from "@mui/material";

const ViewTask = () => {
  const tasks = [
    {
      id: 1,
      label: "Task Name :-",
      name: "Lorem Please attach a doct for verification",
    },
    {
      id: 2,
      label: "Stats :-",
      name: "Pending",
    },
    {
      id: 3,
      label: "Priority :-",
      name: "High",
    },
    {
      id: 4,
      label: "Due Date :-",
      name: "22 Aug 2023",
    },
  ];

  return (
      <div className=" h-100 d-flex m-3 rounded-3 overflow-auto   bg-white ps-4 pe-5 p-4  cad-shadow pb-5 flex-column gap-4">
        <div>
          <Link
            to="/"
            style={{ left: -24 }}
            className="text-primary h6 text-decoration-none d-flex align-items-center gap-3 pointer top-0 "
          >
            <ArrowBackRoundedIcon /> Task Details
          </Link>
        </div>
        <div>
          {tasks.map((task) => (
            <div className="mb-3" key={task.id}>
              <label className="cad-text-medium cad-fw-600 col-2 pb-2">
                {task.label}
              </label>
              <input className="cad-text-medium cad-fw-500 w-50" style={{border:'none'}} value={task.name} readOnly />
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-center">
          <Link to="">
            <Button className="button-contained w-75" variant="contained">
              Start
            </Button>
          </Link>
        </div>
      </div>
  );
};

export default ViewTask;
