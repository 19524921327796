import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const  LightTooltip = styled(({placement, fontSize ,color, className, ...props  }) => (
  <Tooltip {...props} classes={{ popper: className }} placement={placement ? placement : "top"} />
))(({ theme, color, fontSize }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor:  theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    // backgroundColor: color ? color :  theme.palette.common.white,
    // color: color ? color : 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize:  fontSize ? fontSize : 12,
    // fontWeight:600
  },
}
  ));



export default LightTooltip