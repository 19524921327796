import React from "react";
import "./Footer.css";
import { useSelector } from "react-redux";

const Footer = () => {
  const theme = useSelector((state) => state.theme.theme);
  return (
    <div className={`${theme === "light" ? "text-dark" : "text-white"} d-flex mt-4 justify-content-between w-100  `}>
      <span className="cad-text-medium cad-fw-600">
        © 2023, made with ❤︎ by
        <b> CAD IT Solution Team</b> for a better web.
      </span>

      <span className="footer-right d-flex cad-text-medium gap-3 cad-fw-600">
        <span>About Us</span>
        <span>Blog</span>
        <span>Licence</span>

      </span>
    </div>
  );
};

export default Footer;
