import api from './api.service'
let formDataConfig = {
    headers: {
        "Content-Type": "multipart/form-data",
    },
};

// export const updateClient = async (data) => {
//     //console.log((data);
//     try {
//         return await api.put(`/client/`, data, formDataConfig)
//     } catch (error) {
//         return error.response
//     }
// }

export const updateClient = async (data) => {
    try {
        return await api.put(`/agent/`, data, formDataConfig)
    } catch (error) {
        throw error
    }
}

export const changePassword = async (data) => {
    try {
      const user = await api.post(`/auth/agent/changePassword`, data);
      return user;
    } catch (error) {
      throw error;
    }
  };
  

export const createAddressInfo = async (data) => {
    //console.log((data);
    try {
        return await api.put(`/onBoard/client/address`, data, formDataConfig)
    } catch (error) {
        return error.response
    }
}

