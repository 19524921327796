import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { Paper, Skeleton, Tooltip } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import GlobalStateContext from "../../../Contexts/GlobalStateContext";
import GridCard from "./GridCard";
import NoData from "../../../Components/NoData";
import PrimaryButton from "../../../Components/PrimaryButton";
import { getKanban } from "../../../service/task.service";
import { fetchTaskDataByID } from "../../../Redux/Slice/taskSlice";
import Loader from "../../../Components/Loader/Loader";
import { fetchKanbanData } from "../../../Redux/Slice/kanbanSlice";
import Refresher from "../../../Components/Refresher";
import LightTooltip from "../../../Components/Tooltip/LightTooltip";
import SearchInput from "../../../Components/SearchInput/SearchInput";
import ViewModal from "../ViewModal";
import { fetchRequestDocs } from "../../../Redux/Slice/requestDocsSlice";
import { fetchSharedDocs } from "../../../Redux/Slice/getSharedDocs";
import { ToastContainer } from "react-toastify";

const TaskGridView = ({ themeColorsObject, theme }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchKanbanData());
  }, []);

  const { kanbanData, isLoading, mappingKanbanData } = useSelector(
    (state) => state?.kanbanData
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [taskId, setTaskId] = useState(null);

  const filteredTaskArray = mappingKanbanData?.map((task) => {
    const filteredTasks = task?.tasks?.filter(
      (subTask) =>
        subTask?.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        subTask?.employees[0]?.first_name
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
    );
    return { ...task, tasks: filteredTasks };
  });

  function onDragEnd(result) {}

  // Calculate total number of tasks
  const totalTasks = filteredTaskArray?.reduce(
    (total, category) => total + category.tasks.length,
    0
  );

  const [openView, setOpenView] = useState(false);
  const { taskById, tasks } = useSelector((state) => state?.tasks);
  const idj = useSelector((state) => state?.tasks);


  const handleViewOpen = (id) => {
    setTaskId(id)
    dispatch(fetchTaskDataByID(id));
   dispatch(fetchRequestDocs(id))
   dispatch(fetchSharedDocs(id));
    // taskById?.type?.title === "Recevied Document"
    //   ? dispatch(fetchRequestDocs(id))
    //   : dispatch(fetchSharedDocs(id));
    setOpenView(true);
  };
  const handleViewClose = () => setOpenView(false);

  return (
    <div
      style={{
        height: "87vh",
      }}
      className=" w-100 mt-1 rounded-3 mb-4 p-1  d-flex overflow-auto flex-column justify-content-start align-items-start "
    >
      <div
        style={{
          backgroundColor: themeColorsObject.megaCardBackgroundColor,
          color: themeColorsObject.textColor,
        }}
        className="d-flex rounded-3 justify-content-between p-3 cad-shadow mb-3 w-100"
      >
        <div className="col-md-3 mb-1 mb-md-0 col-12 d-flex align-itmes-center">
          <SearchInput
            searchValue={searchTerm}
            searchFucntion={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {/* Refresh button */}
        <div className="d-flex gap-3 align-items-center">
          <div
            className="task-progressbar d-flex pointer"
            style={{
              backgroundColor: "#E0E0E0",
              height: 20,
              borderRadius: 4,
              overflow: "hidden",
            }}
          >
            <LightTooltip
              title={`${filteredTaskArray[0]?.title} tasks ${filteredTaskArray[0]?.tasks?.length} / ${totalTasks}`}
            >
              <div
                style={{
                  width: `${
                    (filteredTaskArray[0]?.tasks?.length / totalTasks) * 100
                  }%`,
                  backgroundColor: "#e74c3c",

                  height: "100%",
                  transition: "0.5s",
                  ":hover": {
                    backgroundColor: "#548CFF", // Change background color on hover
                  },
                }}
              />
            </LightTooltip>

            <LightTooltip
              title={`${filteredTaskArray[1]?.title} tasks ${filteredTaskArray[1]?.tasks?.length} / ${totalTasks}`}
            >
              <div
                style={{
                  width: `${
                    (filteredTaskArray[1]?.tasks?.length / totalTasks) * 100
                  }%`,
                  backgroundColor: "#E39C009d",
                  height: "100%",
                  transition: "0.5s",
                  ":hover": {
                    backgroundColor: "#548CFF", // Change background color on hover
                  },
                  // borderRadius: 0,
                }}
              />
            </LightTooltip>

            <LightTooltip
              title={`${filteredTaskArray[2]?.title} tasks ${filteredTaskArray[2]?.tasks?.length} / ${totalTasks}`}
            >
              <div
                style={{
                  width: `${
                    (filteredTaskArray[2]?.tasks?.length / totalTasks) * 100
                  }%`,
                  backgroundColor: "#548CFF9d",
                  height: "100%",
                  transition: "0.5s",
                  ":hover": {
                    backgroundColor: "#548CFF", // Change background color on hover
                  },
                  // borderRadius: 0,
                }}
              />
            </LightTooltip>

            <LightTooltip
              title={`${filteredTaskArray[3]?.title} tasks ${filteredTaskArray[3]?.tasks?.length} / ${totalTasks}`}
            >
              <div
                style={{
                  width: `${
                    (filteredTaskArray[3]?.tasks?.length / totalTasks) * 100
                  }%`,
                  backgroundColor: "#34A8539d",
                  height: "100%",
                  transition: "0.5s",
                  cursor: "pointer",
                  ":hover": {
                    scale: 1.5,
                  },
                  // borderRadius: "0px 4px 4px 0",
                }}
              />
            </LightTooltip>
          </div>

          <Refresher
            isLoading={isLoading}
            handleRefresh={() => dispatch(fetchKanbanData())}
          />
        </div>
      </div>

      <div
        style={{
          height: "100%",
          backgroundColor: themeColorsObject.megaCardBackgroundColor,
          color: themeColorsObject.textColor,
        }}
        className={`gap-0 rounded-3 w-100 overflow-auto cad-shadow p-3 w-100 justify-content-center d-flex `}
      >
        {filteredTaskArray?.map((category, index) => (
          <div
            key={index}
            className="col-md-3 d-flex  flex-column gap-3 p-2"
            style={{
              backgroundColor: themeColorsObject.megaCardBackgroundColor,
              color: themeColorsObject.titleText,
            }}
          >
            <div
              style={{
                backgroundColor:
                  category.title === "On Hold"
                    ? "#548CFF2d"
                    : category.title === "In Progress"
                    ? "#FCC23D2d"
                    : category.title === "Open"
                    ? "#e74c3c2d"
                    : "#4CA8032d",
                fontWeight: "bold",
                color: "#000",
              }}
              className=" h-auto rounded-3 ps-3 pe-3 p-2 "
            >
              <div
                style={{
                  color: theme === "light" ? "#000" : "#fff",
                }}
                className="d-flex   gap-3 mb-3  align-items-center"
              >
                <span className="cad-fw-700 cad-text-large">
                  {category.title.toUpperCase()}
                </span>
                <Paper
                  className="ps-2 pe-2"
                  style={{
                    backgroundColor:
                      category.title === "On Hold"
                        ? "#548CFF"
                        : category.title === "In Progress"
                        ? "#E39C00"
                        : category.title === "Open"
                        ? "#e74c3c"
                        : "#34A853",
                    fontWeight: "bold",
                    color: "#fff",
                  }}
                >
                  {category.tasks.length}
                </Paper>
              </div>

              {/* Progress bar */}
              <div
                className="mb-3"
                style={{
                  backgroundColor: "#E0E0E0",
                  height: 8,
                  borderRadius: 4,
                }}
              >
                <div
                  style={{
                    width: `${(category.tasks.length / totalTasks) * 100}%`,
                    backgroundColor:
                      category.title === "On Hold"
                        ? "#548CFF"
                        : category.title === "In Progress"
                        ? "#E39C00"
                        : category.title === "Open"
                        ? "#e74c3c"
                        : "#34A853",
                    height: "100%",
                    borderRadius: 4,
                    transition: "0.5s",
                  }}
                />
              </div>

              {/* Render GridCard for each task */}
              {category.tasks.map((task, index) => (
                <GridCard
                  themeColorsObject={themeColorsObject}
                  key={task.id}
                  id={task.id}
                  title={task.title}
                  theme={theme}
                  disc={task.description === null ? "NA" : task.description}
                  tags={task.tags.length === 0 ? [{ title: "NA" }] : task.tags}
                  assignee={task.employees}
                  setOpenView={setOpenView}
                  handleViewOpen={handleViewOpen}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
      <ViewModal
        themeColorsObject={themeColorsObject}
        open={openView}
        handleOpen={handleViewOpen}
        handleClose={handleViewClose}
        id={taskId}
      />
    </div>
  );
};

export default TaskGridView;
