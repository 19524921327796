import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Toster } from "../Login/Login";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, CircularProgress } from "@mui/material";
import * as Yup from "yup";
import { generateNewPassword } from "../../service/auth.service";
import { forgotPasswordCodes } from "../../Utils/StatusCode/index";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const token = searchParams.get("token");

  const [isPasswordLoading, setIsPasswordLoading] = useState(false);

  const passwordSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .matches(/[A-Z]/, "Password must contain atleast one uppercase")
      .matches(/[a-z]/, "Password must contain atleast one lowercase")
      .matches(/[@#&]/, "Password must contain special character @,#,&")
      .min(8, "password must be at least 8 characters"),

    confirmPassword: Yup.string()
      .required("Password is required")
      .matches(/[A-Z]/, "Password must contain atleast one uppercase")
      .oneOf([Yup.ref("password"), null], "Password do not match")
      .matches(/[a-z]/, "Password must contain atleast one lowercase")
      .matches(/[@#&]/, "Password must contain special character @,#,&")
      .min(8, "password must be at least 8 characters"),
  });

  const {
    register: passwordRegister,
    handleSubmit: handlePasswordSubmit,
    formState: { errors: passErrors }, // Changed from passwordFormState to formState
    reset: resetPassword,
  } = useForm({ mode: "all", resolver: yupResolver(passwordSchema) });

  const [showPassword, setShowPassword] = useState(false);
  const [showCreatePassword, setShowCreatePassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle the state to show/hide password
  };

  const toggleCreatePasswordVisibility = () => {
    setShowCreatePassword(!showCreatePassword); // Toggle the state to show/hide password
  };

  const onConfirmPasswordSubmit = async (value) => {
    //console.log((value);

    const { status } = await generateNewPassword(value, id, token);
    //console.log((status);
    if (status === forgotPasswordCodes.STATUS_SUCCESS) {
      navigate("/login");
      <Alert severity="success">Success</Alert>;
    } else if (status === forgotPasswordCodes.STATUS_NOT_FOUND) {
      <Alert severity="error">Not Found</Alert>;
    }

    resetPassword();
  };

  return (
    <div className="login  container-fluid">
      <div className="container d-flex align-items-center w-100  h-100">
        <div className="d-flex w-100 justify-content-center align-items-center  h-75 p-0">
          <div className="col-left pe-3 pag-2 d-flex flex-column  col-7 h-50 d-flex align-items-start justify-content-center">
            <h1 style={{ fontSize: "60px" }} className="cad-fw-800">
              Cloudify <span className="cad-fw-500">Docs</span>
            </h1>
            <p style={{ color: "#1c1e21" }} className="fs-4 cad-fw-600">
              Empowering Visions, Engineering Solutions: Your CAD IT Partner.
            </p>
          </div>

          <div className=" col-right col-4 h-auto  p-3">
            <form
              onSubmit={handlePasswordSubmit(onConfirmPasswordSubmit)}
              className={`d-flex flex-column  justify-content-start h-100`}
            >
              <div>
                <label
                  htmlFor="createPassword1"
                  className="form-label text-primary"
                >
                  Create Password
                </label>

                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    style={{
                      padding: "12px",
                    }}
                    className="form-control ps-3 border-primary"
                    id="createPassword1"
                    placeholder="Create password"
                    {...passwordRegister("password")}
                  />
                  <button
                    className="btn btn-outline-primary "
                    type="button"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <VisibilityOffIcon className="cad-text-large" />
                    ) : (
                      <RemoveRedEyeIcon className="cad-text-large" />
                    )}
                  </button>
                </div>
                <label
                  htmlFor="exampleInputPassword1"
                  className="form-label text-primary form-text text-danger"
                >
                  {passErrors.password?.message}
                </label>
              </div>

              <div>
                <label
                  htmlFor="exampleInputPassword1"
                  className="form-label text-primary"
                >
                  Confirm Password
                </label>
                <div className="input-group">
                  <input
                    type={showCreatePassword ? "text" : "password"}
                    style={{
                      padding: "12px",
                    }}
                    className="form-control ps-3 border-primary"
                    id="exampleInputPassword1"
                    placeholder="Confirm password"
                    {...passwordRegister("confirmPassword")}
                  />
                  <button
                    className="btn btn-outline-primary "
                    type="button"
                    onClick={toggleCreatePasswordVisibility}
                  >
                    {showCreatePassword ? (
                      <VisibilityOffIcon className="cad-text-large" />
                    ) : (
                      <RemoveRedEyeIcon className="cad-text-large" />
                    )}
                  </button>
                </div>
                <label
                  htmlFor="exampleInputPassword1"
                  className="form-label text-primary form-text text-danger"
                >
                  {passErrors.confirmPassword?.message}
                </label>
              </div>
              <Button
                type="submit"
                className={`${
                  isPasswordLoading ? "opacity-75" : ""
                } btn mt-2 pt-2 fs-5 cad-fw-700 pb-2 button-contained`}
              >
                {isPasswordLoading ? (
                  <CircularProgress size={36} color="inherit" />
                ) : (
                  "Submit"
                )}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
