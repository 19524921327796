import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import SpaceDashboardTwoToneIcon from "@mui/icons-material/SpaceDashboardTwoTone";
import { useDispatch } from "react-redux";
import { toggleTheme } from "../../Redux/Slice/theme.Slice";
import FullscreenRoundedIcon from "@mui/icons-material/FullscreenRounded";

const SettingTab = ({ setIsSettingOpen, isSettingOpen }) => {
  const { theme, themeColorsObject } = useSelector((state) => state.theme);

  const dispatch = useDispatch();

  const handleToggleTheme = (themeVarient) => {
    dispatch(toggleTheme(themeVarient));
    setIsSettingOpen(!isSettingOpen);
  };

  const toggleFullScreen = () => {
    const doc = window.document;
    const docEl = doc.documentElement;

    const requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;
    const cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
    } else {
      cancelFullScreen.call(doc);
    }

    setIsSettingOpen(!isSettingOpen);
  };

  const changeFontFamily = (newFontFamily) => {
    //console.log((newFontFamily);
    // setFontFamily(newFontFamily);
    document.body.style.setProperty(
      "font-family",
      `${newFontFamily}, sans-serif`,
      "important"
    );

    // document.documentElement.style.setProperty('--global-font-family', `${newFontFamily}, sans-serif`);
  };

  return (
    <div
      style={{
        right: 0,
        top: 0,
        width: 280,
        height: "100vh",
        transform: isSettingOpen ? "translateX(0px)" : "translateX(400px)",
        zIndex: 99999,
        backgroundColor: themeColorsObject.megaCardBackgroundColor,
        color: themeColorsObject.textColor,
        transition: "0.5s",
      }}
      className="position-absolute "
    >
      <div className=" d-flex justify-content-between p-3 align-items-center border-bottom">
        <span className="cad-fw-600 cad-text-large ">Settings</span>
        <CloseIcon
          onClick={() => setIsSettingOpen(!isSettingOpen)}
          className="cad-text-xlarge pointer rounded-2 action-hover"
        />
      </div>

      <div className=" w-100 d-flex flex-column">
        {/* ==========[ PRESETS ]================ */}
        <div className="d-flex gap-2 justify-content-start flex-wrap p-2">
          <span className="w-100 cad-text-medium p-2 ">Presets</span>
          <span
            //THEME HANDLER
            onClick={() => handleToggleTheme("light")}
            style={{
              backgroundColor: "#F5F5F5",
              opacity: theme === "light" ? 1 : 0.2,
              height: 70,
              // transition:"0.2s"
            }}
            className="p-2 rounded-3  col-3 d-flex justify-content-center align-items-center pointer action-hover"
          >
            <SpaceDashboardTwoToneIcon
              style={{
                fontSize: theme === "light" ? "66px" : "36px",
                color: "#1976D2",
                transition: "0.2s",
              }}
            />
          </span>

          <span
            //THEME HANDLER
            onClick={() => handleToggleTheme("dark")}
            style={{
              backgroundColor: "#292F4C",
              opacity: theme === "dark" ? 1 : 0.2,
              height: 70,
              // transition:"0.2s"
            }}
            className="p-2 rounded-3 col-3 d-flex justify-content-center align-items-center pointer action-hover"
          >
            <SpaceDashboardTwoToneIcon
              style={{
                fontSize: theme === "dark" ? "66px" : "36px",
                color: "#AACCDF",
                transition: "0.2s",
              }}
            />
          </span>

          <span
            //THEME HANDLER
            onClick={() => handleToggleTheme("dark-black")}
            style={{
              backgroundColor: "#282828",
              opacity: theme === "dark-black" ? 1 : 0.2,
              height: 70,
              // transition:"0.2s"
            }}
            className="p-2 rounded-3 col-3 d-flex justify-content-center align-items-center pointer action-hover"
          >
            <SpaceDashboardTwoToneIcon
              style={{
                fontSize: theme === "dark-black" ? "66px" : "36px",
                color: "#B0BEC5",
                transition: "0.2s",
              }}
            />
          </span>
        </div>

        {/* ==========[ FONTS ]================ */}
        <div className="d-flex gap-2 justify-content-start flex-wrap p-2">
          <span className="w-100 cad-text-medium p-2 ">Presets</span>
          <span
            //THEME HANDLER
            onClick={() => changeFontFamily("Open Sans")}
            style={{
              backgroundColor: "#F5F5F5",
              opacity: false ? 1 : 0.2,
              height: 80,
              // transition:"0.2s"
            }}
            className="p-2 rounded-3  col-4 d-flex justify-content-center align-items-center pointer action-hover"
          >
            <h1
              style={{
                fontSize: false ? "66px" : "36px",
                color: "#1976D2",
                transition: "0.2s",
              }}
            >
              A
            </h1>
          </span>

          <span
            //THEME HANDLER
            onClick={() => changeFontFamily("Fredoka")}
            style={{
              backgroundColor: "#F5F5F5",
              opacity: false ? 1 : 0.2,
              height: 80,
              // transition:"0.2s"
            }}
            className="p-2 rounded-3  col-4 d-flex justify-content-center align-items-center pointer action-hover"
          >
            <h1
              style={{
                fontSize: false ? "66px" : "36px",
                color: "#1976D2",
                transition: "0.2s",
              }}
            >
              A
            </h1>
          </span>
        </div>

        {/* ==========[ FULL SCREEN TOGGLER ]================ */}
        <div
          onClick={toggleFullScreen}
          className="position-absolute bottom-0 w-100 d-flex justify-content-center p-3 border-top pointer"
        >
          <span className="d-flex gap-2">
            {" "}
            <FullscreenRoundedIcon /> Full Screen
          </span>
        </div>
      </div>
    </div>
  );
};

export default SettingTab;
