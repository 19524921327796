import api from "./api.service";
import TokenService from "./token.service";

export const emailCheck = async (data) => {
  try {
    const user = await api.post(`/auth/public/checkEmailAddress`, data);
    return user;
  } catch (error) {
    throw error.response;
  }
};

export const forgotPassword = async (data) => {
  try {
    const user = await api.post(`/auth/public/forgetPassword`, data);
    return user;
  } catch (error) {
    return error.response;
  }
};

export const authMeService = async () => {
  
  try {
    return await api.get(`/auth/me`);
  } catch (error) {
    throw error.response;
  }
};

export const generateNewPassword = async (data, id, token) => {
  try {
    const user = await api.post(
      `/auth/public/resetPassword/${id}/${token}`,
      data
    );
    return user;
  } catch (error) {
    return error.response;
  }
};

export const login = async (data) => {
  try {
    const user = await api.post(`/auth/client/login`, data);
    if (user.status === 200) {
      TokenService.setUser(user.data);
    }
    return user;
  } catch (error) {
    return error.response;
  }
};
