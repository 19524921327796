import { Button, Modal } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const AlertModal = ({ open, closeModal, handler, message }) => {
    const { theme, themeColorsObject } = useSelector((state) => state.theme);
  
      const dispatch = useDispatch()
  
  
  
      return (
          <Modal
            open={open}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="cad-modal-blur"
          >
            <div
                  className="d-flex justify-content-evenly flex-column align-items-center"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    padding: 20,
                    p: 2,
                    pt: 3,
                    backgroundColor:themeColorsObject.backgroundColor,
                    color:themeColorsObject.textColor,
                    height: 200,
                    borderRadius: 8,
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  }}
                >
              <h5 className="text-center">{message}</h5>
              <div className="w-100 d-flex justify-content-around">
                <div className="col-5">
                  <Button
                    onClick={handler}
                    className="btn button-contained "
                  >
                    Yes
                  </Button>
                </div>
                <div className="col-5">
                  <Button onClick={closeModal} className="btn button-outlined">
                    No
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
      )
  }
  
  export default AlertModal;