
const Tag = ({ title }) => {
    return (
      <span
        style={{
          backgroundColor: "#DEE6F8",
          fontSize: "12px",
          color: "#216AFF",
        }}
        className="p-1 ps-3 pe-3 fw-bold rounded-1"
      >
        {title}
      </span>
    );
  };

  export default Tag