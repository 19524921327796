import React, { Fragment, useEffect, useState } from "react";
import "./Login.css";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import EmailField from "../../Components/EmailField/EmailField";
import ConfirmPasswordField from "../../Components/EmailField/ConfirmPasswordField";
import PasswordField from "../../Components/EmailField/PasswordField";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



const Login = () => {
  const [status, setStatus] = useState(200);
  const [emailSwitch, setEmailSwitch] = useState(true);
  const [ authorized, setAuthorized ] = useState(true)

  
  const [openDialogue, setOpenDialogue] = useState(false);
  const [email, setEmail] = useState("");

  const handleClickOpen = (status) => {
    setStatus(status)
    setOpenDialogue(true);
  };

  let logScreen

  if (status === 200 || status === 205) {
    logScreen = <EmailField
    setEmail={setEmail}
    handleClickOpen={handleClickOpen}
    setStatus={setStatus}
    setEmailSwitch={setEmailSwitch}
    />
  } else if(status === 202 ) {
    logScreen = <PasswordField
    email={email} 
    setStatus={setStatus}
    setEmailSwitch={setEmailSwitch}
    
    setAuthorised={setAuthorized}/>
  } else if(status === 401 ){
    logScreen = <PasswordField
    email={email} 
    setStatus={setStatus}
    setEmailSwitch={setEmailSwitch}
    setAuthorised={setAuthorized}/>
  }

  return (
    <div
    style={{
      background:
        "linear-gradient(90deg, hsla(203, 69%, 84%, 0.5) 0%, hsla(18, 76%, 85%, 0.5) 100%)",
    }} className="login  container-fluid">
      {/* <span className="login-tag cad-fw-700 cad-text-large">Client</span> */}

      <div className="container d-flex align-items-center w-100  h-100">
        <div className="d-flex w-100 justify-content-center align-items-center  h-75 p-0">
          <div className="col-left pe-3 pag-2 d-flex flex-column  col-7 h-50 d-flex align-items-start justify-content-center">
            <h1 style={{ fontSize: "60px" }} className="cad-fw-800">
            Cloudify<span className="cad-fw-500">Docs</span>
            </h1>
            <p style={{ color: "#1c1e21" }} className="fs-4 cad-fw-600">
              Empowering Visions, Engineering Solutions: Your CAD IT Partner.
            </p>
          </div>

          <div className=" col-right col-4 h-auto  p-3">
          {logScreen}
          </div>
        </div>
      </div>





    </div>
  );
};

export default Login;
