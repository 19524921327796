import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useDispatch } from "react-redux";

import wordLogo from "../../Images/wordLogo.png";
import pdfLogo from "../../Images/pdfLogo.png";
import noData from "../../Images/no-data.png";
import DateFormatter from "../../Components/DateFormatter";
import Tag from "../../Components/Tag/Tag";
import { fetchRequestDocs } from "../../Redux/Slice/requestDocsSlice";
import {
  createRequestDocs,
  updateCompleteTask,
} from "../../service/task.service";
import MainModal from "../../Components/MainModal";
import UserProfile from "../../Components/UserProfile";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { notify } from "../../App";
import gallery from "../../Images/gallery.png";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import LightTooltip from "../../Components/Tooltip/LightTooltip";
import DoneIcon from '@mui/icons-material/Done';

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ViewModal = ({ open, handleClose, id, themeColorsObject }) => {
  const [isUploading, setIsUploading] = useState(false);
  const dispatch = useDispatch();

  const [isFilled, setIsFilled] = useState(false);
  const { taskById, isLoading } = useSelector((state) => state?.tasks);
  const { authInfo } = useSelector((state) => state?.fetchAuthMe);

  useEffect(() => {
    if (Array.isArray(taskById?.FormHistoryCopy) && taskById?.FormHistoryCopy.length) {
      const filteredItems = taskById?.FormHistoryCopy.filter((item) => item.created_by === authInfo?.id);
      setIsFilled(filteredItems.length > 0);
    }
  }, [authInfo?.id, taskById?.FormHistoryCopy]);

  // console.log(isFilled);

  const [selectedFile, setSelectedFile] = useState(null);
  const [reciveDocArray, setReciveDocArray] = useState([]);

  const foundObject = taskById;
  //console.log((foundObject);
  const { forms, documents } = foundObject;

  const { sharedDocuments } = useSelector((state) => state?.getSharedDocs);

  const { requestDocuments, isrequestDocumentsLoading } = useSelector((state) => state?.getRequestDocs);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleComplete = (e) => {
    handleClose();
    e.preventDefault();
    updateCompleteTask(id);

    // getAllTask()
  };

  const bytesToKB = (bytes) => {
    return (bytes / 1024).toFixed(2);
  };

  const openFile = (filePath, fileType) => {
    if (fileType === "pdf") {
      // Open PDF directly
      // window.open(filePath, '_blank');
      return;
    } else if (fileType === "docx") {
      // Open Word document using Office Online Viewer
      const officeViewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
        filePath
      )}`;
      window.open(officeViewerUrl, "_blank");
    } else {
      // Download other file types
      fetch(filePath)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filePath.split("/").pop()); // Use the file name from the URL
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          console.error("There was a problem with the file download:", error);
        });
    }
  };

  const downloadFile = (filePath, fileName) => {
    fetch(process.env.REACT_APP_DOWLOAD_URL + "/" + filePath)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        notify("Document downloaded");
        setTimeout(() => {
          openFile(
            process.env.REACT_APP_DOWLOAD_URL + "/" + filePath,
            filePath.split(".").pop()
          );
        }, 3000);
      })
      .catch((error) => {
        console.error("There was a problem with the file download:", error);
      });
  };

  const handleFileUpload = async (receivedDocID, file, fileName) => {
    setIsUploading(true);
    setSelectedFile(file);
    setReciveDocArray([...reciveDocArray, file[0]]);
    // Create form data

    if (selectedFile !== null) {
      if (
        selectedFile[0].type === "application/vnd.ms-excel" &&
        selectedFile[0].type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        setSelectedFile(null);
        setIsUploading(false);
        return notify("Invalid file format", "error");
      }
      return
    }

    const formData = new FormData(); // Assuming 'file' is the key for the uploaded file
    formData.append("title", fileName);
    if (file?.length) {
      for (let i = 0; i < file?.length; i++) {
        formData.append(`documents`, file[i]);
      }
    }

    try {
      const response = await createRequestDocs(id, receivedDocID, formData);

      setIsUploading(false);
      dispatch(fetchRequestDocs(id));
      notify("Document uploaded successfully");
      //console.log(("File uploaded successfully:", response.data);
      // You can perform further actions based on the response if needed
    } catch (error) {
      notify("Invalid file format", "error");
      console.error("Error uploading file:", error);
      // Handle error
    }
  };

  console.log(reciveDocArray[1]);

  return (
    <MainModal
      title={"Task Details"}
      width={"60%"}
      height={"92%"}
      open={open}
      handleClose={() => {
        handleClose();
        setReciveDocArray([]);
      }}
    >
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="d-flex  flex-wrap position-relative">
            <div className="col-4 mb-4 d-flex flex-column gap-2">
              <div
                style={{
                  color: themeColorsObject.titleText,
                }}
                className="cad-text-medium cad-fw-500 "
              >
                Task Name
              </div>
              <div className="cad-text-medium cad-fw-600 ">
                {taskById?.title}
              </div>
            </div>

            {/* <div className="col-4 mb-4 d-flex flex-column gap-2 ">
              <div
                style={{
                  color: themeColorsObject.titleText,
                }}
                className="cad-text-medium  cad-fw-500 col-3"
              >
                Task type
              </div>
              <div className="cad-text-medium cad-fw-600 ">
                {taskById?.type?.title}
              </div>
            </div> */}

            <div className="col-4 mb-4 d-flex flex-column gap-2">
              <div
                style={{
                  color: themeColorsObject.titleText,
                }}
                className="cad-text-medium cad-fw-500 "
              >
                Due Date
              </div>
              <div className="cad-text-medium cad-fw-600 ">
                <DateFormatter inputDate={taskById?.due_date} />
              </div>
            </div>

            <div className="col-4 mb-4 d-flex flex-column gap-2">
              <div
                style={{
                  color: themeColorsObject.titleText,
                }}
                className="cad-text-medium cad-fw-500 "
              >
                Assigned Date
              </div>
              <div className="cad-text-medium  cad-fw-600 ">
                <DateFormatter inputDate={taskById?.createdAt} />
              </div>
            </div>

            {/* ===[ Status ]=== */}

            <div className="col-4 mb-4 d-flex flex-column gap-2">
              <div
                style={{
                  color: themeColorsObject.titleText,
                }}
                className="cad-text-medium  cad-fw-500 col-4"
              >
                Priority{" "}
              </div>
              <Chip
                style={{ fontWeight: "600", width: "100px" }}
                size="small"
                className={`${taskById?.priority?.title === "Open"
                  ? "chip-low"
                  : taskById?.priority?.title === "Low"
                    ? "chip-low"
                    : taskById?.priority?.title === "High"
                      ? "chip-high"
                      : "chip-med"
                  }`}
                label={
                  taskById?.priority?.title === "Open"
                    ? "Open"
                    : taskById?.priority?.title === "Low"
                      ? "Low"
                      : taskById?.priority?.title === "High"
                        ? "High"
                        : "Medium"
                }
              />
            </div>

            <div className="col-4 mb-4 d-flex flex-column gap-2">
              <div
                style={{
                  color: themeColorsObject.titleText,
                }}
                className="cad-text-medium  cad-fw-500"
              >
                Status
              </div>
              <Chip
                style={{ fontWeight: "600", width: "100px" }}
                size="small"
                className={`${taskById?.status?.title === "Open"
                  ? "chip-high"
                  : taskById?.status?.title === "On Hold"
                    ? "chip-high"
                    : taskById?.status?.title === "In Progress"
                      ? "chip-med"
                      : "chip-low"
                  }`}
                label={
                  taskById?.status?.title === "Open"
                    ? "Open"
                    : taskById?.status?.title === "On Hold"
                      ? "On Hold"
                      : taskById?.status?.title === "In Progress"
                        ? "In Progress"
                        : "Completed"
                }
              />
            </div>

            <div className="col-4 mb-4 d-flex flex-column gap-2 ">
              <div
                style={{
                  color: themeColorsObject.titleText,
                }}
                className="cad-text-medium  cad-fw-600 "
              >
                Assigned By
              </div>
              {/* {taskById?.employees.map((el, i) => ( */}
              <div
                // key={i}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <UserProfile
                  fw={"600"}
                  fs="15px"
                  proPic={taskById?.employees[0]?.profile_photo}
                  companyName={taskById?.employees[0]?.first_name}
                />
              </div>
              {/* ))} */}
            </div>

            <div className="col-12 mb-4 d-flex flex-column gap-2 flex-wrap">
              <div
                style={{
                  color: themeColorsObject.titleText,
                }}
                className="cad-text-medium cad-fw-500 "
              >
                Tag :
              </div>
              <div className="cad-text-medium d-flex gap-2 cad-text-dark flex-wrap cad-fw-600">
                {taskById?.tags?.map((tag, i) => (
                  <Tag key={i} title={tag?.title} />
                ))}
              </div>
            </div>
            <div className="col-12 d-flex flex-column gap-2 flex-wrap">
              <div
                style={{
                  color: themeColorsObject.titleText,
                }}
                className="cad-text-medium cad-fw-500 "
              >
                Description :
              </div>
              <p className="cad-text-medium p-2 ps-0 cad-fw-600">
                {taskById?.description}{" "}
              </p>
            </div>
          </div>

          <div>
            {foundObject?.type?.title === "Recevied Document" ? (
              <div className=" w-100 pb-3">
                {requestDocuments.length > 0 ? (
                  <>
                    <h4 className="pb-2">
                      Please Upload Documents Requested Below
                    </h4>
                    <TableContainer className="h-100">
                      <Table sx={{ minWidth: 500 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{
                                color: themeColorsObject.titleText,
                                borderBottom: `1px dotted ${themeColorsObject.titleText}`,
                              }}
                              width={"25%"}
                              className="cad-fw-600 pt-2 pb-2 cad-text-small  d-inline-block"
                            >
                              Document Name
                            </TableCell>
                            <TableCell
                              style={{
                                color: themeColorsObject.titleText,
                                borderBottom: `1px dotted ${themeColorsObject.titleText}`,
                              }}
                              width={"30%"}
                              className="cad-fw-600 pt-2 pb-2 cad-text-small d-inline-block"
                              align="left"
                            >
                              Description
                            </TableCell>
                            <TableCell
                              style={{
                                color: themeColorsObject.titleText,
                                borderBottom: `1px dotted ${themeColorsObject.titleText}`,
                              }}
                              width={"30%"}
                              className="cad-fw-600 pt-2 pb-2 cad-text-small d-inline-block"
                              align="left"
                            >
                              Note
                            </TableCell>
                            <TableCell
                              style={{
                                color: themeColorsObject.titleText,
                                borderBottom: `1px dotted ${themeColorsObject.titleText}`,
                              }}
                              width={"15%"}
                              className="cad-fw-600 pt-2 pb-2 cad-text-small d-inline-block"
                              align="center"
                            >
                              Action
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {requestDocuments?.map((eachDocs, index) => (
                            <>
                              <TableRow
                                className="pointer border-none"
                                key={eachDocs.id}
                                onMouseEnter={(e) => {
                                  e.currentTarget.style.backgroundColor =
                                    "transparent";
                                  e.currentTarget.style.transition = "0.5s";
                                  e.currentTarget.style.boxShadow =
                                    "rgba(0, 0, 0, 0.24) 0px 3px 8px";
                                  // setMouseEntered(true);
                                  // setMouseEnteredId(id)
                                }}
                                onMouseLeave={(e) => {
                                  e.currentTarget.style.backgroundColor =
                                    "transparent"; // Revert to default background color on hover out
                                  e.currentTarget.style.scale = 1;
                                  e.currentTarget.style.transition = "0.5s";
                                  e.currentTarget.style.boxShadow = "none";
                                  // setMouseEntered(false);
                                }}
                              >
                                <TableCell
                                  style={{
                                    color: themeColorsObject.textColor,
                                  }}
                                  className="cad-text-small border-0 pt-3 pb-3 text-truncate d-inline-block text-truncate"
                                  component="th"
                                  scope="row"
                                  width={"25%"}
                                >
                                  <LightTooltip placement="top-start" title={eachDocs.title}>
                                    {eachDocs.title}
                                  </LightTooltip>
                                </TableCell>
                                <TableCell
                                  style={{
                                    color: themeColorsObject.textColor,
                                  }}
                                  className="cad-text-small border-0 pt-3 pb-3 text-truncate d-inline-block text-truncate"
                                  align="left"
                                  width={"30%"}
                                >
                                  <LightTooltip placement="top-start" title={eachDocs.description}>
                                    {eachDocs.description}
                                  </LightTooltip>
                                </TableCell>
                                <TableCell
                                  style={{
                                    color: themeColorsObject.textColor,
                                  }}
                                  className="cad-text-small border-0 pt-3 pb-3 text-truncate d-inline-block text-truncate"
                                  align="left"
                                  width={"30%"}
                                >
                                  <LightTooltip placement="top-start" title={eachDocs.note}>
                                    {eachDocs.note}
                                  </LightTooltip>
                                </TableCell>
                                <TableCell
                                  style={{
                                    color: themeColorsObject.textColor,
                                  }}
                                  className="cad-text-small border-0 pt-3 pb-3  text-truncate d-inline-block text-truncate"
                                  align="center"
                                  width={"15%"}
                                >
                                  <label
                                    htmlFor={`upload-${eachDocs.id}`}
                                    className={`btn ${eachDocs?.isUploaded
                                      ? "btn-success"
                                      : "btn-primary"
                                      }  cad-text-small p-0 pb-1 ps-2 pe-2`}
                                    style={{
                                      cursor: eachDocs?.isUploaded
                                        ? "no-drop"
                                        : "pointer",
                                    }}
                                  >
                                    {eachDocs?.isUploaded
                                      ? "Uploaded"
                                      : "Upload"}
                                  </label>
                                  <input
                                    type="file"
                                    id={`upload-${eachDocs.id}`}
                                    style={{ display: "none" }}
                                    onChange={(e) =>
                                      handleFileUpload(
                                        eachDocs.id,
                                        e.target.files,
                                        eachDocs.title
                                      )
                                    }
                                    disabled={eachDocs?.isUploaded}
                                  />
                                </TableCell>
                              </TableRow>
                              <div className="d-flex flex-wrap gap-2 m-2">
                                {reciveDocArray[index] === null ||
                                  reciveDocArray[index] === undefined ? (
                                  !eachDocs?.isUploaded ? (
                                    <span className="text-danger d-flex align-items-center gap-1 cad-text-medium cad-fw-500 p-1 pb-0">
                                      <ErrorOutlineRoundedIcon className="cad-text-large" />{" "}
                                      Document not selected yet
                                    </span>
                                  ) : (
                                    ""
                                  )
                                ) : reciveDocArray[index]?.type ===
                                  "image/png" ||
                                  reciveDocArray[index]?.type ===
                                  "image/jpeg" ||
                                  reciveDocArray[index]?.type ===
                                  "image/gif" ? (
                                  <span className="d-flex flex-wrap gap-2 align-items-end">
                                    <img
                                      src={gallery}
                                      alt=""
                                      width={25}
                                      height={25}
                                    />
                                    <span className="d-flex align-items-center gap-2">
                                      {reciveDocArray[index].name}{" "}
                                      <span className="action-hover rounded-2 pointer d-flex align-items-center">
                                        <CloseIcon
                                          onClick={() => {
                                            // setSelectedFile(null);
                                            reciveDocArray.splice(index, 1);
                                          }}
                                          className="text-danger cad-text-large"
                                        />
                                      </span>
                                    </span>
                                  </span>
                                ) : reciveDocArray[index]?.type ===
                                  "application/msword" ||
                                  reciveDocArray[index]?.type ===
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                                  <span className="d-flex flex-wrap gap-2 align-items-end">
                                    <img
                                      src={wordLogo}
                                      alt=""
                                      width={25}
                                      height={25}
                                    />
                                    <span className="d-flex align-items-center gap-2">
                                      {reciveDocArray[index].name}{" "}
                                      <span className="action-hover rounded-2 pointer d-flex align-items-center">
                                        <CloseIcon
                                          onClick={() => {
                                            reciveDocArray.splice(index, 1);
                                          }}
                                          className="text-danger cad-text-large"
                                        />
                                      </span>
                                    </span>
                                  </span>
                                ) : reciveDocArray[index]?.type ===
                                  "application/pdf" ? (
                                  <span className="d-flex flex-wrap gap-2 align-items-end">
                                    <img
                                      src={pdfLogo}
                                      alt=""
                                      width={25}
                                      height={25}
                                    />
                                    <span className="d-flex align-items-center gap-2">
                                      {reciveDocArray[index].name}{" "}
                                      <span className="action-hover rounded-2 pointer d-flex align-items-center">
                                        <CloseIcon
                                          onClick={() => {
                                            reciveDocArray.splice(index, 1);
                                          }}
                                          className="text-danger cad-text-large"
                                        />
                                      </span>
                                    </span>
                                  </span>
                                ) : !eachDocs?.isUploaded ? (
                                  <span className="text-danger d-flex align-items-center gap-1 cad-text-medium cad-fw-500 p-1 pb-0">
                                    <ErrorOutlineRoundedIcon className="cad-text-large" />{" "}
                                    Invalid file format{" "}
                                    <span className="action-hover rounded-2 pointer d-flex align-items-center">
                                      <CloseIcon
                                        onClick={() => {
                                          // reciveDocArray.splice(index, 1)
                                          console.log(reciveDocArray);
                                        }}
                                        className="text-danger cad-text-large"
                                      />
                                    </span>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                ) : (
                  <div className=" w-100 p-5  d-flex flex-column align-items-center gap-2">
                    <img src={noData} width={40} alt="" />
                    <span className="text-primary cad-text-medium cad-fw-500">
                      NoDocs
                    </span>
                  </div>
                )}
              </div>
            ) : foundObject?.type?.title === "Fill Form" ? (
              <div className=" w-100 pb-3">
                {forms?.length < 0 ? (
                  <div className=" w-100 p-5   d-flex flex-column align-items-center gap-2">
                    <img src={noData} width={40} alt="" />
                    <span className="text-primary cad-text-medium cad-fw-500">
                      No Form
                    </span>
                  </div>
                ) : (
                  <>
                    <h4 className="pb-2">Please fill attached form</h4>
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{
                                color: themeColorsObject.titleText,
                                borderBottom: `1px dotted ${themeColorsObject.titleText}`,
                                width: "3%",
                              }}
                              className="cad-fw-600 pt-2 pb-2 cad-text-small"
                            >
                              Sr No.
                            </TableCell>
                            <TableCell
                              style={{
                                color: themeColorsObject.titleText,
                                borderBottom: `1px dotted ${themeColorsObject.titleText}`,
                                width: "30%",
                              }}
                              className="cad-fw-600 pt-2 pb-2 cad-text-small"
                              align="left"
                            >
                              Form Name
                            </TableCell>
                            <TableCell
                              style={{
                                color: themeColorsObject.titleText,
                                borderBottom: `1px dotted ${themeColorsObject.titleText}`,
                                width: "5%",
                              }}
                              className="cad-fw-600 pt-2 pb-2 cad-text-small"
                              align="center"
                            >
                              Action
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {forms?.map((form, index) => (
                            <TableRow
                              key={form.id + index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              onMouseEnter={(e) => {
                                e.currentTarget.style.backgroundColor =
                                  "transparent";
                                e.currentTarget.style.transition = "0.5s";
                                e.currentTarget.style.boxShadow =
                                  "rgba(0, 0, 0, 0.24) 0px 3px 8px";
                                e.currentTarget.style.cursor = "pointer";
                                // setMouseEntered(true);
                                // setMouseEnteredId(id)
                              }}
                              onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor =
                                  "transparent"; // Revert to default background color on hover out
                                e.currentTarget.style.scale = 1;
                                e.currentTarget.style.transition = "0.5s";
                                e.currentTarget.style.boxShadow = "none";
                                // setMouseEntered(false);
                              }}
                            >
                              <TableCell
                                style={{
                                  color: themeColorsObject.textColor,
                                }}
                                component="th"
                                className=" pt-3 pb-3 cad-text-small"
                                scope="row"
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell
                                style={{
                                  color: themeColorsObject.textColor,
                                }}
                                className=" pt-3 pb-3 cad-text-small"
                                align="left"
                              >
                                {form?.title}
                                {isFilled &&
                                  <Chip
                                    sx={{ ml: 2, fontSize: 10 }}
                                    label="Already Filled"
                                    size="small"
                                    color="success" variant="outlined"
                                    icon={<DoneIcon />}
                                  />
                                }
                              </TableCell>
                              <TableCell
                                style={{
                                  color: themeColorsObject.textColor,
                                }}
                                className=" pt-3 pb-3 cad-text-small"
                                align="center"
                              >
                                {foundObject?.status?.title === "Open" ||
                                  foundObject?.status?.title === "In Progress" ? (
                                  <Link to={`/fill-form/${id}/${form?.id}`}>
                                    <button className="btn btn-primary cad-text-small p-0 pb-1 ps-2 pe-2">
                                      Fill Form
                                    </button>
                                  </Link>
                                ) : (
                                  <button
                                    disabled={true}
                                    className="btn btn-success cad-text-small p-0 pb-1 ps-2 pe-2"
                                  >
                                    Already filled
                                  </button>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </div>
            ) : (
              <div className=" w-100 pb-3">
                {sharedDocuments?.length < 0 ? (
                  <div className=" w-100 p-5   d-flex flex-column align-items-center gap-2">
                    <img src={noData} width={40} alt="" />
                    <span className="text-primary cad-text-medium cad-fw-500">
                      No Form
                    </span>
                  </div>
                ) : (
                  <>
                    <h4 className="pb-2">Please Review Documents</h4>
                    <TableContainer className="h-100">
                      <Table sx={{ minWidth: 500 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{ color: themeColorsObject.titleText }}
                              className="cad-fw-600 cad-text-small"
                              align="left"
                            >
                              Doc Type
                            </TableCell>
                            <TableCell
                              style={{
                                color: themeColorsObject.titleText,
                                width: 200,
                              }}
                              className="cad-fw-600 cad-text-small"
                            >
                              Name
                            </TableCell>
                            <TableCell
                              style={{
                                color: themeColorsObject.titleText,
                                width: 200,
                              }}
                              className="cad-fw-600 cad-text-small"
                            >
                              Description
                            </TableCell>
                            <TableCell
                              style={{ color: themeColorsObject.titleText }}
                              className="cad-fw-600 cad-text-small"
                              align="left"
                            >
                              Size
                            </TableCell>
                            <TableCell
                              style={{ color: themeColorsObject.titleText }}
                              className="cad-fw-600 cad-text-small"
                              align="center"
                            >
                              Download
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {isLoading ? (
                            // Show skeleton loading for the entire table rows
                            <span>Loading...</span>
                          ) : (
                            sharedDocuments?.map((eachDocs) => (
                              <TableRow
                                className="pointer border-none"
                                key={eachDocs.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                                onMouseEnter={(e) => {
                                  e.currentTarget.style.backgroundColor =
                                    "transparent";
                                  e.currentTarget.style.transition = "0.5s";
                                  e.currentTarget.style.boxShadow =
                                    "rgba(0, 0, 0, 0.24) 0px 3px 8px";
                                  // setMouseEntered(true);
                                  // setMouseEnteredId(id);
                                }}
                                onMouseLeave={(e) => {
                                  e.currentTarget.style.backgroundColor =
                                    "transparent"; // Revert to default background color on hover out
                                  e.currentTarget.style.scale = 1;
                                  e.currentTarget.style.transition = "0.5s";
                                  e.currentTarget.style.boxShadow = "none";
                                  // setMouseEntered(false);
                                }}
                              >
                                <TableCell
                                  style={{
                                    color: themeColorsObject.textColor,
                                  }}
                                  className="cad-text-small"
                                  align="left"
                                >
                                  <img
                                    src={
                                      eachDocs?.document?.mimetype
                                        .split("/")
                                        .pop() === "pdf"
                                        ? pdfLogo
                                        : eachDocs?.document?.mimetype
                                          .split("/")
                                          .pop() === "jpeg" ||
                                          eachDocs?.document?.mimetype
                                            .split("/")
                                            .pop() === "png"
                                          ? gallery
                                          : wordLogo
                                    }
                                    width={20}
                                    alt="doc-img"
                                  />{" "}
                                  <span>
                                    {eachDocs?.document?.mimetype
                                      .split("/")
                                      .pop() === "pdf"
                                      ? "Pdf"
                                      : eachDocs?.document?.mimetype
                                        .split("/")
                                        .pop() ||
                                        (eachDocs?.document?.mimetype
                                          .split("/")
                                          .pop() ===
                                          "png") ===
                                        "jpeg"
                                        ? "Image"
                                        : "Word"}
                                  </span>
                                </TableCell>
                                <TableCell
                                  style={{
                                    color: themeColorsObject.textColor,
                                  }}
                                  className="cad-text-small"
                                  component="th"
                                  scope="row"
                                >
                                  {eachDocs.title}
                                </TableCell>
                                <TableCell
                                  style={{
                                    color: themeColorsObject.textColor,
                                  }}
                                  className="cad-text-small"
                                  component="th"
                                  scope="row"
                                >
                                  {eachDocs?.description}
                                </TableCell>
                                <TableCell
                                  style={{
                                    color: themeColorsObject.textColor,
                                  }}
                                  className="cad-text-small"
                                  align="left"
                                >
                                  {bytesToKB(eachDocs?.document?.size)} KB
                                </TableCell>
                                <TableCell
                                  style={{
                                    color: themeColorsObject.textColor,
                                  }}
                                  onClick={() =>
                                    downloadFile(
                                      eachDocs?.document?.path,
                                      eachDocs?.document?.originalname
                                    )
                                  }
                                  className="cad-text-small"
                                  align="center"
                                >
                                  <FileDownloadOutlinedIcon className="action-hover rounded-2" />
                                </TableCell>
                              </TableRow>
                            ))
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </MainModal>
  );
};

export default ViewModal;
