import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { getForms, createForm } from "../../service/form.service";

// export const fetchForms = createAsyncThunk(
//   "getForms",
//   async (id) => {
//     try {
//       const res = await getForms(id);
//       //console.log((res.data, id);
//       return res.data;
//     } catch (err) {
//       throw new Error(`Error fetching forms: ${err.message}`);
//     }
//   }
// );

// export const addForm = createAsyncThunk(
//   "addForm",
//   async (formData) => {
//     try {
//       const res = await createForm(formData);
//       //console.log((res.data);
//       return res.data;
//     } catch (err) {
//       throw new Error(`Error creating form: ${err.message}`);
//     }
//   }
// );

const getFormsSlice = createSlice({
  name: "forms",
  initialState: {
    isLoading: false,
    isError: false,
    error: null,
    forms: [],
    filledForm:[]
  },
  reducers: {
    // Reducer to handle adding a new form to the state
    addNewForm: (state, action) => {
      state.forms = action.payload;
    },
  },
  //   extraReducers: (builder) => {
  //     builder
  //       .addCase(fetchForms.pending, (state) => {
  //         state.isLoading = true;
  //         state.isError = false;
  //         state.error = null;
  //       })
  //       .addCase(fetchForms.fulfilled, (state, action) => {
  //         state.forms = action.payload?.data || [];
  //         state.isLoading = false;
  //       })
  //       .addCase(fetchForms.rejected, (state, action) => {
  //         state.isLoading = false;
  //         state.isError = true;
  //         state.error = action.error.message;
  //       })
  //       .addCase(addForm.fulfilled, (state, action) => {
  //         state.forms.push(action.payload);
  //       });
  //   },
});

export const { addNewForm } = getFormsSlice.actions;
export default getFormsSlice.reducer;
