import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useState } from 'react';

const Refresher = ({ handleRefresh, isLoading}) => {
    // const [ isLoading, setIsLoading ] = useState(false)

    // const handleRefresh = () => {
    //     setIsLoading(!isLoading)
    // }


  return (
    <span
      style={{ padding: "0.3rem 0.3rem" }}
      className="pointer action-hover  rounded-2 "
      onClick={handleRefresh}
    >
      <AutorenewIcon className={`cad-text-xlarge ${isLoading ? "refresher" : ""}`} />
    </span>
  );
};

export default Refresher;
