import { configureStore } from "@reduxjs/toolkit";

import { combineReducers } from "redux";

import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { encryptTransform } from "redux-persist-transform-encrypt";
import thunk from "redux-thunk";
import taskSlice from "./Slice/taskSlice";
import getSharedDocs from "./Slice/getSharedDocs";
import requestDocsSlice from "./Slice/requestDocsSlice";
import getFormsSlice from "./Slice/getFormsSlice";
import fetchAuthMe from "./Slice/fetchAuthMe";
import themeSlice from "./Slice/theme.Slice";
import kanbanSlice from "./Slice/kanbanSlice";
import notificationData from "./Slice/notification.service.slice";

const rootReducer = combineReducers({
  tasks: taskSlice,
  getSharedDocs: getSharedDocs,
  getRequestDocs: requestDocsSlice,
  getFormsSlice: getFormsSlice,
  fetchAuthMe: fetchAuthMe,
  theme: themeSlice,
  kanbanData: kanbanSlice,
  notification: notificationData
});

const encryptor = encryptTransform({
  secretKey: "myDatabaseKey",
  onError: function (error) {
    //console.log((error);
  },
});

const persistConfig = {
  key: "root",
  storage,
  transforms: [encryptor],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(thunk),
});

