import { Modal } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import FormPrevField from "./FormPrevField";
import { useState } from "react";
import jsPDF from 'jspdf';

const style = {
  height: "95%",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function replaceUnderscoresWithSpaces(str) {
  return str?.replace(/_/g, " ");
}

const FormPreviewModal = ({ open, setOpen, formData }) => {
  // if("geolocation" in navigator){
  //   navigator.geolocation.getCurrentPosition((position)=>{
  //     let lat = position.coords.latitude
  //     let long = position.coords.longitude

  //     //console.log((lat, long);
  //   })
  // }

  // //console.log((formData);

  const { forms } = useSelector((state) => state?.getFormsSlice);

  // //console.log((forms?.length);

  const array1 = forms; // First array
  const array2 = formData; // Second array

  const mergedArray = [];

  array1.forEach((obj1) => {
    const obj2Index = array2?.findIndex((obj2) => obj2.type === obj1.type);

    if (obj2Index !== -1) {
      // Merge objects with the same "type"
      const mergedObject = { ...obj1, ...array2[obj2Index] };
      mergedArray.push(mergedObject);

      // Remove the matched object from array2
      array2.splice(obj2Index, 1);
    } else {
      mergedArray.push(obj1);
    }
  });

  // Add remaining objects from array2
  mergedArray.push(...array2);

  //console.log((mergedArray);


// Filter objects with section key set to "details" and type key set to "text"
const detailsTextInputs = mergedArray.filter(field => field.section === "details" && field.type === "text");

// Extract values of text input fields from the filtered objects
const detailsValues = detailsTextInputs.reduce((values, field) => {
    // Add the text input value to the values object
    values[field.name] = field.value;
    return values;
}, {});

//console.log((detailsValues);



//console.log((mergedArray);


  return (
    <Modal
      open={open}
      onClose={() => setOpen(!open)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="cad-modal-blur"
    >
      

      <div
        style={{
          // backgroundColor: formData[0]?.backgroundColor,
          // color: formData[0]?.color,
          height: "95%",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          p: 4,
          width: "65%",
        }}
        id="my-form"
        className="d-flex  rounded-0 pb-0 flex-column gap-2 overflow-auto"
      >
        <span
          style={
            {
              // backgroundColor: formData[0]?.formColor,
            }
          }
          className={`p-3 bg-white  h-100 ${
            formData?.length !== forms?.length ? "h-auto" : "h-auto"
          }`}
        >
          <div
            style={
              {
                // border: `0.1px solid ${formData[0]?.color}`,
              }
            }
            className="d-flex flex-wrap w-100 h-100 justify-content-start gap-3  p-2 m-0"
          >
            {false ? (
              <div className="w-100 h-100 text-danger border border-danger d-flex justify-content-center gap-2 flex-column align-items-center">
                <h1>
                  <WarningAmberRoundedIcon style={{ fontSize: "80px" }} />
                </h1>
                <h3 className="cad-fw-600">Please fill all flields !</h3>
              </div>
            ) : (
              mergedArray?.map((field, index) => {
                switch (field.type) {
                  case "header":
                    return (
                      <div
                        style={{
                          // border: `1px solid ${formData[0].color}`,
                          borderBottom: `1px solid #000000`,
                        }}
                        className={`${field.alignment} position-relative  p-4 pb-2   w-100 d-flex justify-content-center flex-column`}
                      >
                        <h3 className={`${field.className} cad-fw-700 `}>
                          {field.label}
                        </h3>
                        <p className={field.subTitleClassName}>
                          {field.subTitle}
                        </p>
                      </div>
                    );

                  // ====[ width 50% ]===
                  case "text":
                  case "number":
                  case "date":
                  case "email":
                  // case "dropdown":
                  case "select-one":
                  case "checkbox":
                  case "radio":
                    return field.section === "details" ? (
                      <></>
                    ) : (
                      <FormPrevField
                        name={field.name}
                        value={field.value}
                        formData={formData}
                      />
                    );

                  // ====[ width 100% ]===
                  case "textarea":
                    return (
                      <FormPrevField
                        width={"100%"}
                        name={field.name}
                        value={field.value}
                        formData={formData}
                      />
                    );

                  case "paragraph":
                    return (
                      <div className="col-12">
                        <div
                          className={`${field.alignment} position-relative p-2 gap-2 d-flex flex-column`}
                        >
                          <p className={`${field.className}  cad-fw-500`}>
                            {field.label}
                          </p>
                        </div>
                      </div>
                    );

                  case "hr":
                    return (
                      <div className="position-relative w-100 p-2 gap-2 d-flex flex-column">
                        <span
                          className="mt-2 mb-2 w-100"
                          style={{
                            borderTop: `0.5px solid #000`,
                          }}
                        />
                      </div>
                    );

                  case "footer":
                    return (
                      <div className="w-100 mt-3">
                        <div
                          style={{
                            borderTop: `1px solid ${formData.color}`,
                            borderTop: `1px solid #000000`,
                          }}
                          className={`${field.alignment} position-relative ps-4 pt-2 pb-3 pe-4 pb-0 w-100 d-flex justify-content-between `}
                        >
                          <span className={`${field.className} cad-fw-700`}>
                            {field.label}
                          </span>
                          <span className={field.subTitleClassName}>
                            {field.subTitle}
                          </span>
                        </div>
                      </div>
                    );

                  // =====[ above is copmleted ]=====

                  case "details-heading":
                    return (
                      <div className="position-relative p-2 gap-2 d-flex flex-column">
                        <label className="cad-text-large cad-fw-700">
                          {field.label}
                        </label>
                        <label className="cad-text-medium cad-fw-500">
                          {field.subTitle}
                        </label>
                      </div>
                    );

                  case "details":
                    return (
                      <div className=" w-100 p-2 gap-2 d-flex flex-column">
                        <div className="d-flex  flex-wrap w-100">
                          <label className="col-12 mb-1 mt-2 cad-fw-600">
                            {field.detailsTitle}
                          </label>



                          {field?.inputsArray.map((input, index) => (
                              <div
                                key={index}
                                className="col-3 p-1  flex-column d-flex justify-content-start mb-2"
                              >
                                <input
                                  type="text"
                                  style={{
                                    borderColor: formData[0]?.color,
                                    border: `1px dotted #000000`,
                                  }}
                                  className="form-control cad-text-small m-1 rounded-0 "
                                  placeholder={field?.value}
                                  // value={td[index]}
                                  // onChange={(e) => handleInputChange(e, index)}
                                />
                                <span className="cad-text-small ps-1">
                                  {input?.fieldInput}
                                </span>
                              </div>
                            ))}




                          {/* <div className="col-4 p-2 ps-0 flex-column d-flex justify-content-start mb-2">
                            <span
                              style={{
                                border: `1px dotted #000000`,
                              }}
                              className="cad-text-medium w-100 cad-fw-500 p-2"
                            >
                              {replaceUnderscoresWithSpaces(field?.name)}
                            </span>
                            <span className="cad-text-small ps-1">
                              {field?.fieldOne}
                            </span>
                          </div> */}


                        </div>
                      </div>
                    );

                  case "submit":
                    return (
                      <div
                        // onDragOver={(e)=>handleElementDragOver(e, index)}
                        className="w-100"
                      >
                        {replaceUnderscoresWithSpaces(field.name)}
                      </div>
                    );

                  case "file":
                    return (
                      <div
                        // onDragOver={(e)=>handleElementDragOver(e, index)}
                        className="w-100"
                      >
                        {replaceUnderscoresWithSpaces(field.name)}
                      </div>
                    );

                  case "table":
                    return (
                      <div
                        // onDragOver={(e)=>handleElementDragOver(e, index)}
                        className="w-100"
                      >
                        {replaceUnderscoresWithSpaces(field.name)}
                      </div>
                    );

                  case "signeture":
                    return (
                      <div
                        // onDragOver={(e)=>handleElementDragOver(e, index)}
                        className="w-100"
                      >
                        {replaceUnderscoresWithSpaces(field.name)}
                      </div>
                    );

                  default:
                    return null;
                }
              })
            )}
          </div>
        </span>
      </div>
   
    </Modal>
  );
};

export default FormPreviewModal;
