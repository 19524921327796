import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getNotification } from "../../service/notification.service";

export const getNotificationData = createAsyncThunk("getNotification", async () => {
    try {
        const res = await getNotification();
        // console.log(res.data);
        return res?.data?.data;
    } catch (err) {
        throw new Error(`Error fetching data: ${err.message}`);
    }
});

const getNotificationSlice = createSlice({
    name: "notification",
    initialState: {
        isLoading: false,
        isError: false,
        error: null,
        notification: [], // Initialize rows as an empty array
        notification_count: 0
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getNotificationData.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.error = null;
            })
            .addCase(getNotificationData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.notification = action.payload || [];
                const unreadNotifications = action.payload?.filter(item => !item.isRead) || [];
                state.notification_count = unreadNotifications.length;
            })
            .addCase(getNotificationData.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.error = action.error.message; // Store the error message
            });

    },
})


export default getNotificationSlice.reducer;

