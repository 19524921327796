import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
// import { persistor, store } from './app/store';
import { store } from './Redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import GlobalStateProvider from './Contexts/GlobalStateProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Router>
      <GlobalStateProvider>
        <StrictMode>
          <App />
        </StrictMode>
      </GlobalStateProvider>
    </Router>
  </Provider>
);
reportWebVitals();
