import axios from "axios";
import TokenService from "./token.service";

const instance = axios.create({
  baseURL:
    process.env.REACT_APP_BACKEND_BASEURL +
    "/api/" +
    process.env.REACT_APP_NODE_ENV +
    "/v1",
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    // //console.log((token);
    if (token) {
      config.headers["x-auth-token"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      if (err.response.status === 403 && !originalConfig.__isRetryRequest) {
        // handle infinite loop
        originalConfig._retry = true;

        // //console.log(("refresh", TokenService.getLocalRefreshToken());

        // when the refresh token are expires handle loop
        if (err.response.status === 401 && !originalConfig.__isRetryRequest) {
          console.log("hit");
          originalConfig.__isRetryRequest = true;
          return err.response;
        }

        try {
          const rs = await instance.post("/auth/client/token", {
            refreshToken: TokenService.getLocalRefreshToken(),
          });

          //console.log(("Response:", rs);

          const { accessToken } = rs.data;

          TokenService.updateNewAccessToken(accessToken);

          return instance(originalConfig);
        } catch (_error) {
          // //console.log((_error.response)
          if (_error.response.status === 401) {
            // dispatch(userAuthenticate);
            TokenService.isAuthenticateFailed();
            return Promise.reject(_error);
          }
          return Promise.reject(_error);
        }
      }
      if (err.response.status === 401) TokenService.isAuthenticateFailed();
      return Promise.reject(err);
    }
    return Promise.reject(err);
  }
);

export default instance;
