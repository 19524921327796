import api from "./api.service";
let formDataConfig = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

let headers = {
  "Content-Type": "multipart/form-data",
};

export const getAgent = async () => {
  try {
    const response = await api.get(`/global/agent/`);
    // //console.log((response);
    return response;
  } catch (error) {
    // Handle error or return the error response
    return error.response;
  }
};

