import { createSlice } from "@reduxjs/toolkit";
import * as themeColors from "../../Utils/Theme/Colors.js";

const themeSlice = createSlice({
  name: "theme",
  initialState: {
    theme: "light", // Initial theme is light
    themeColorsObject: themeColors.lightTheme
  },
  reducers: {
    toggleTheme: (state, action) => {
      return {
        ...state,
        theme:  action.payload ,
        themeColorsObject: action.payload === "light" ? themeColors.lightTheme : action.payload === "dark" ? themeColors.darkTheme : themeColors.darkBlackTheme
      };
    },
  },
});

export const { toggleTheme } = themeSlice.actions;
export default themeSlice.reducer;
