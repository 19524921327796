import { Paper } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { authMeService } from "../../service/auth.service";
import { convertTimestampToTime } from "../ChatComponent/ChatsLeft";
import UserProfile from "../GlobalTable/UserProfile";
// import "./loading.css";

const ChatBox = ({ chatArray, proPic, isTyping }) => {
  const [myId, setMyId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const chatWindowRef = useRef(null);
  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    const fetchAuthMe = async () => {
      setIsLoading(true);
      try {
        const res = await authMeService();
        setMyId(res?.data?.data?.id);
        setIsLoading(false);
      } catch (err) {
        // Catch the error and handle it
        //console.log((err.message);
      }

      // newSocket.emit("joinChat", chatActiveId)
    };

    fetchAuthMe();
    scrollToBottom();
  }, [chatArray]);

  return (
    <div
      ref={chatWindowRef}
      className="chat-screen mb-5 p-3 h-100 w-100 d-flex flex-column overflow-auto"
    >
      {chatArray?.map(({ content, id, sender, createdAt }, index) => (
        <div
          key={id}
          className={
            sender?.id === myId
              ? // true
                "sending-msg-bubble-chat-box mb-3"
              : "chat-receiving-msg-bubble mb-4 "
          }
        >
          <div
            // className={chat.sender ? "d-none" : "d-block"}
            style={{ position: "absolute", top: "-6px", left: "-49px" }}
          >
            {sender?.id !== myId && (
              <UserProfile proPic={sender?.profile_photo} />
            )}
          </div>
          <span className="msg">{content}</span>
          <span className="msg-time">{createdAt && convertTimestampToTime(createdAt)}</span>
        </div>
      ))}
      {/* {isTyping ? (
        <div class="bubble mb-3">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      ) : null} */}
    </div>
  );
};

export default ChatBox;
