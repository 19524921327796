import Chat from "./Chats/Chat";
import ViewTask from "./Pages/ViewTask/ViewTask";
import Profile from "./Pages/Profile/Profile";
// import Settings from "./Pages/Settings/Settings";
import Task from "./Pages/Task/Task";
import { Login } from "@mui/icons-material";
import ConfirmPassword from "./Pages/ConfirmPassword/ConfirmPassword";
import FormCanvas from "./Pages/FillForm/FormCanvas";
import FillForm from "./Pages/FillForm/FillForm";
import PersonalDetailsNew from "./Components/PersonalDetails/PersonalDetailsNew";

export const RouteLink = [
  { path: "/", component: <Task /> },
  { path: "/profile", component: <PersonalDetailsNew /> },
  { path: "/chats", component: <Chat /> },
  { path: "/fill-form", component: <FillForm /> },
  { path: "/view-task/:id", component: <ViewTask /> },
  { path: "/fill-form/:id1/:id2", component: <FillForm /> },
  // { path: "/confirm-password", component: <ConfirmPassword /> },
  { path: "/login", component: <Login /> },
];
