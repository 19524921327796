import React, { useEffect, useState } from "react";
// import './Chat.css'
import ChatsLeft from "../Components/ChatComponent/ChatsLeft";
import ChatsRight from "../Components/ChatComponent/ChatsRight";
import io from "socket.io-client";
import TokenService from "../service/token.service";
import { useSelector } from "react-redux";
import { getContacts } from "../service/chat.service";
import * as themeColors from "../Utils/Theme/Colors";

const Chat = () => {
  const { theme, themeColorsObject } = useSelector((state) => state.theme);
  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState([]);
  const [receivedMessages, setReceivedMessages] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [activeChatIndex, setActiveChatIndex] = useState(null);
  const [chatContacts, setChatContacts] = useState([]);
  const [chatActiveId, setChatActiveId] = useState(null);

  const [openNewChat, setOpenNewChat] = useState(false);
  const handleOpenNewChat = () => setOpenNewChat(true);
  const handleCloseNewChat = () => setOpenNewChat(false);

  const [openNewGroupChat, setOpenGroupNewChat] = useState(false);
  const handleOpenNewGroupChat = () => setOpenGroupNewChat(true);
  const handleCloseNewGroupChat = () => setOpenGroupNewChat(false);

  const [isTyping, setIsTyping] = useState(false);
  const [isOnline, setIsOnline] = useState(false);

  const fetchContacts = async () => {
    //console.log(("hiited");
    try {
      const response = await getContacts();
      //console.log((response);
      response?.data?.payload.map((chats) => {
        // //console.log((chats);
        let profileName;
        if (!chats?.isGroupChat) {
          const { first_name, last_name, profile_photo, messages } =
            chats?.participants[0];
          profileName = {
            name: first_name + " " + last_name,
            profile: profile_photo,
            chatId: chats?.id,
            first_name,
            last_name,
            // last_message: messages[0]?.content
          };
        } else {
          profileName = {
            name: chats.name,
            profile: chats.profile,
            chatId: chats?.id,
          };
        }
        return profileName;
        // //console.log((profileName);
        // const userName = chats?.
      });
      // Assuming response.data.payload[0].participants contains the contacts array
      // setContacts(response?.data?.payload[0]?.participants || []);
      setContacts(
        response?.data?.payload.map((chats) => {
          //console.log((chats);
          let profileName;
          if (!chats?.isGroupChat) {
            const { first_name, last_name, profile_photo } =
              chats?.participants[0];
            profileName = {
              name: first_name + " " + last_name,
              profile: profile_photo,
              chatId: chats?.id,
              first_name,
              last_name,
              last_message: chats?.messages[0],
            };
          } else {
            profileName = {
              name: chats.name,
              profile: chats.profile,
              chatId: chats?.id,
            };
          }
          return profileName;
          // //console.log((profileName);
          // const userName = chats?.
        }) || []
      );
      //console.log(("contacts====", response);
    } catch (error) {
      //console.log((error);
    }
  };

  const socketOn = () => {
    const token = TokenService.getLocalAccessToken();
    const newSocket = io(process.env.REACT_APP_DOWLOAD_URL, {
      withCredentials: false,
      auth: { token },
    });
    return newSocket;
  };

  useEffect(() => {
    const token = TokenService.getLocalAccessToken();
    const newSocket = io(process.env.REACT_APP_DOWLOAD_URL, {
      withCredentials: false,
      auth: { token },
    });

    setSocket(newSocket);

    newSocket.on("messageReceived", (data) => {
      setIsOnline(true);
      //console.log(("Received new message:", data);
      setMessage(data?.payload);
      fetchContacts();
    });

    newSocket.on("typing", (chatActiveId) => {
      setIsOnline(true);
      //console.log(("typing");
      //console.log((chatActiveId);
    });
    chatActiveId && newSocket.emit("joinChat", chatActiveId);

    return () => {
      newSocket.disconnect();
      setIsOnline(false);
    };
  }, [chatActiveId]);

  return (
    <div
      style={{
        height: "87vh",
        backgroundColor: themeColorsObject.megaCardBackgroundColor,
        color: themeColorsObject.textColor,
      }}
      className=" cad-shadow mt-1   rounded-3 mb-4 pt-2"
    >
      <div className="d-flex w-100  h-100">
        <div className="col-4  h-100">
          <ChatsLeft
            isTyping={isTyping}
            handleCloseNewChat={handleCloseNewChat}
            openNewChat={openNewChat}
            handleOpenNewChat={handleOpenNewChat}
            handleCloseNewGroupChat={handleCloseNewGroupChat}
            openNewGroupChat={openNewGroupChat}
            handleOpenNewGroupChat={handleOpenNewGroupChat}
            activeChatIndex={activeChatIndex}
            setActiveChatIndex={setActiveChatIndex}
            chatActiveId={chatActiveId}
            setChatActiveId={setChatActiveId}
            contacts={contacts}
            setContacts={setContacts}
            chatContacts={chatContacts}
            message={message}
            setMessage={setMessage}
            setChatContacts={setChatContacts}
          />
        </div>

        <div
          style={{
            borderLeft: "1px solid #DEE2E6",
          }}
          className="col-8 p-0 h-100"
        >
          <ChatsRight
            isOnline={isOnline}
            themeColorsObject={themeColorsObject}
            setIsOnline={setIsOnline}
            setIsTyping={setIsTyping}
            isTyping={isTyping}
            socket={socket}
            contacts={contacts}
            handleCloseNewChat={handleCloseNewChat}
            openNewChat={openNewChat}
            handleOpenNewChat={handleOpenNewChat}
            activeChatIndex={activeChatIndex}
            chatActiveId={chatActiveId}
            message={message}
            setMessage={setMessage}
            chatContacts={chatContacts}
          />
        </div>
      </div>
    </div>
  );
};
export default Chat;
