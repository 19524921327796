import * as React from "react";
import { emphasize, styled } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import HomeIcon from "@mui/icons-material/Home";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}


export default function CustomizedBreadcrumbs({ route, path }) {

  return (
    <div role="presentation" onClick={handleClick} className="pointer text-primary">
      <Breadcrumbs aria-label="breadcrumb">
        <StyledBreadcrumb
          className="text-primary cad-fw-600"
          style={{
            backgroundColor:"transparent"
          }}
          component={Link}
          to="/"
          label="Task"
          // icon={<HomeIcon fontSize="small" />}
        />
        {route === "Dashboard" ? (
          ""
        ) : (
          <StyledBreadcrumb
          className="text-primary cad-fw-600"
          style={{
            backgroundColor:"transparent"
          }}
            component={Link}
            to={path} // Assuming route is the correct path
            label={route}
          />
        )}
      </Breadcrumbs>
    </div>
  );
}
