import React from 'react'

const FormPrevField = ({formData, name, value, width}) => {

  console.log(name, value, width);
    
function replaceUnderscoresWithSpaces(str) {
    return str?.replace(/_/g, " ");
  }
  
  return (
    <div
      style={{
        // border: `0.1px solid ${formData[0]?.color}`,
        // border: `1px solid #000000`,
        width: width ? width : "49%",
      }}
    >
      <div className="p-2 gap-2 d-flex flex-column">
        <label className="cad-text-small cad-fw-500">
          {replaceUnderscoresWithSpaces(name)}
        </label>
        <span style={{
            
        border: `1px dotted #000000`,
        }}
        className="cad-text-medium cad-fw-500 p-2">
          {value}
        </span>
      </div>
    </div>
  )
}

export default FormPrevField