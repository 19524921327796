import React from "react";
import { Avatar, AvatarGroup, Paper, Typography } from "@mui/material";
import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DriveFileRenameOutlineTwoToneIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import RemoveRedEyeTwoToneIcon from "@mui/icons-material/RemoveRedEyeTwoTone";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Tag from "../../../Components/Tag/Tag";
import { fetchRequestDocs } from "../../../Redux/Slice/requestDocsSlice";
import { fetchSharedDocs } from "../../../Redux/Slice/getSharedDocs";
import { fetchKanbanData } from "../../../Redux/Slice/kanbanSlice";
import UserProfile from "../../../Components/GlobalTable/UserProfile";
import ViewModal from "../ViewModal";
import { useState } from "react";
import { fetchTaskDataByID } from "../../../Redux/Slice/taskSlice";
import LightTooltip from "../../../Components/Tooltip/LightTooltip";
import { isArray } from "jquery";

function stringToColor(string) {
  let hash = 0;
  let i;

  // / eslint-disable no-bitwise /
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  // / eslint-enable no-bitwise /

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}`,
  };
}

const GridCard = ({
  id,
  title,
  disc,
  tags,
  assignee,
  themeColorsObject,
  theme,
  handleViewOpen,
}) => {
  console.log(assignee);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // ===============================================================

  const { tasks } = useSelector((state) => state?.tasks);
  // ================================================================
  // ===============================================================


  // ================================================================

  return (
    <div
      style={{
        backgroundColor: themeColorsObject.megaCardBackgroundColor,
        // color: themeColorsObject.textColor,
        color: theme === "light" ? "#000" : "#fff",
      }}
      className="p-3 grid-card mb-2 rounded-1 pointer d-flex flex-column gap-2  "
      onClick={() => handleViewOpen(id)}
    >
      <div className="d-flex justify-content-between ">
        <span className="fw-bold cad-text-medium text-truncate">{title}</span>

        {/* <span
          onClick={() => handleViewOpen(id)}
          className="p-2 action-hover rounded-3 d-flex align-items-center"
        >
          <RemoveRedEyeTwoToneIcon className="cad-text-large " />
        </span> */}
      </div>
      <span className="cad-fw-500 cad-text-small text-truncate">
        {disc}
      </span>

      <div className="d-flex flex-wrap gap-2  mb-2 mt-2">
        {tags?.map((tag, i) => (
          <Tag key={i} title={tag.title} />
        ))}
      </div>
      <LightTooltip fontSize={12} placement={"top-end"} title={`${assignee[0]?.first_name} ${assignee[0]?.last_name}`}>
        <div className="d-flex justify-content-end flex-wrap gap-2 pointer  mb-2 mt-2">
          {assignee?.length > 0 ? <UserProfile proPic={assignee[0]?.profile_photo} /> : "No Assignee"}

        </div>
      </LightTooltip>

    </div>
  );
};

export default GridCard;
